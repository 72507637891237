import { useStore } from '../../../store'
import { Breadcrumbs, Typography, Skeleton, Box } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import styled from '@emotion/styled'
import Link from 'next/link'
import { IBreadcrumbElement } from '../../../types'
import { ReactElement } from 'react'

const StyledBreadcrumb = styled(Breadcrumbs)`
  li {
    cursor: pointer;
  }

  li:last-child {
    color: #b6ea42;
    h6 {
      font-weight: 600;
    }
  }
`
const SkeletonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 160px;
`
const SkeletonElement = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 75px;
`

const SkipdBreadcrumbSkeleton = (): ReactElement => (
  <SkeletonContainer>
    {[1, 2].map((b) => (
      <SkeletonElement key={b}>
        <DoubleArrowIcon fontSize="small" />
        <Skeleton variant="rectangular" width={50} height={20} />
      </SkeletonElement>
    ))}
  </SkeletonContainer>
)

const SkipdBreadcrumb = (): ReactElement => {
  const { breadcrumb, isLoading } = useStore()

  const items = breadcrumb.map((bc: IBreadcrumbElement, index) => (
    <Link href={bc.url} key={index}>
      <Typography variant="subtitle1">{bc.title}</Typography>
    </Link>
  ))

  return (
    <>
      {isLoading.main ? (
        <SkipdBreadcrumbSkeleton />
      ) : (
        <>
          <DoubleArrowIcon fontSize="small" style={{ marginRight: '10px' }} />
          <StyledBreadcrumb
            aria-label="breadcrumb"
            separator={<DoubleArrowIcon fontSize="small" />}
          >
            {items}
          </StyledBreadcrumb>
        </>
      )}
    </>
  )
}
export default SkipdBreadcrumb

import { useState, useEffect, MouseEvent, ReactElement } from 'react'
import { useStore } from '../../../store'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Searcher from '../../common/Searcher'
import { FaGlobeAmericas } from 'react-icons/fa'
import SignUpModal from '../../common/SignUpModal'
import LoginModal from '../../common/LoginModal'
import Alert from '@mui/material/Alert'
import SkipdBreadcrumb from '../../common/SkipdBreadcrumb'

import Link from 'next/link'
import { Auth } from 'aws-amplify'
import { useRouter } from 'next/router'
import { useUser } from '../../../context/Auth'
import useS3 from '../../../utils/useS3'
import styled from '@emotion/styled'
import ForgotPassowrd from '../../common/ForgotPassword'

/*const SoundIcon = styled.div`
  background-color: #2b2841;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 48px;
  border-radius: 4px;
  & svg {
    color: #fff;
    font-size: 16px;
  }
`*/

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2000;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 70px;
`
interface ProfilePhotoProps {
  url: string
}

const NavBarTop = (): ReactElement => {
  //TODO: REVIEW WHY SOMETIMES WHEN UPDATE THE USER PICTURE IT LOOPS

  const { claims, user, setClaims } = useUser()
  const [s3] = useS3()
  const router = useRouter()

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [profilePhoto, setProfilePhoto] = useState<null | ProfilePhotoProps>()
  const {
    breadcrumb,
    editProfile,
    setFirstTimeProfile,
    openSignUp,
    openLogin,
    openForgotPassword,
    isFirstTimeProfile,
    alertMessage,
    notFound,
    setOpenSignUp,
    setOpenLogin,
    setAlertMessage,
    setCloseAlert,
    cleanProfile,
  } = useStore()

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
    setAnchorElNav(null)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }

  const handleOpenLogin = (): void => {
    setOpenLogin()
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const handleOpenSignUp = (): void => {
    setOpenSignUp()
    setAnchorElNav(null)
    setAnchorElUser(null)
  }

  const handleMyProfile = (): void => {
    router.push(`${process.env.NEXT_PUBLIC_ROOT}/profile/${claims?.id}`)
  }

  const handleLogout = async (): Promise<void> => {
    await Auth.signOut()
    setClaims(null)
    cleanProfile()
  }

  useEffect(() => {
    if (notFound) {
      router.push('/404')
    }
  }, [notFound])

  useEffect(() => {
    const handleData = async (): Promise<void> => {
      if (claims.profilePhoto) {
        const url = await s3.getImageUrl(
          `${claims?.id}/${claims?.profilePhoto}`
        )
        setProfilePhoto({ url })
      }
    }

    if (isFirstTimeProfile && claims?.id && user) {
      const username = user.getUsername()
      const firstProfileInfo = {
        profile: { id: claims?.id },
        name: username.substring(0, username.lastIndexOf('@')),
      }
      editProfile(firstProfileInfo, null)
      setFirstTimeProfile(false)
      setAlertMessage(
        `Welcome to Skip'd. Add a nice pic and a good name`,
        'success'
      )
      router.push(`${process.env.NEXT_PUBLIC_ROOT}/profile/${claims.id}/edit`)
    } else {
      if (claims && claims?.id) handleData()
    }
  }, [claims, isFirstTimeProfile, user])

  useEffect(() => {
    let closeAlert
    if (alertMessage.message)
      closeAlert = setTimeout(() => {
        setCloseAlert()
      }, 5000)
    return () => clearTimeout(closeAlert)
  }, [alertMessage, setCloseAlert])

  return (
    <>
      {alertMessage.message ? (
        <AlertContainer>
          <Alert
            severity={alertMessage.severity}
            icon={false}
            onClose={setCloseAlert}
          >
            {alertMessage.message}
          </Alert>
        </AlertContainer>
      ) : null}
      <AppBar
        position="sticky"
        sx={{ backgroundColor: 'rgba(0,0,0,0.4)', boxShadow: 0, flexGrow: 1 }}
      >
        <Container maxWidth={false} sx={{ zIndex: 1000 }}>
          <Toolbar disableGutters>
            <Box sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
              <Link href={`${process.env.NEXT_PUBLIC_ROOT}`} passHref>
                <span style={{ cursor: 'pointer' }}>
                  <FaGlobeAmericas style={{ fontSize: 25 }} />
                </span>
              </Link>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <Link href={`${process.env.NEXT_PUBLIC_ROOT}`} passHref>
                  <span style={{ cursor: 'pointer' }}>
                    <FaGlobeAmericas style={{ fontSize: 20 }} />
                  </span>
                </Link>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {/* Alonzo: I documented this option in the menu because it is not functional right now
                <MenuItem
                  onClick={() => router.push(`${process.env.NEXT_PUBLIC_ROOT}`)}
                >
                  <Typography textAlign="center">Explore</Typography>
              </MenuItem>
                <MenuItem>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>*/}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
              }}
            >
              {breadcrumb.length ? (
                <SkipdBreadcrumb />
              ) : (
                <>
                  {/* Alonzo: I documented this option in the menu because it is not functional right now
                   <Button
                    onClick={() =>
                      router.push(`${process.env.NEXT_PUBLIC_ROOT}`)
                    }
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Explore
                  </Button> 
                  {user && claims?.id ? (
                    <Button
                      onClick={() =>
                        router.push(
                          `${process.env.NEXT_PUBLIC_ROOT}/profile/${claims.id}`
                        )
                      }
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      My Profile
                    </Button>
                  ) : null}*/}
                  <Searcher />
                  {/*<SoundIcon>
                    <IconButton>
                      <FaVolumeUp />
                    </IconButton>
                </SoundIcon>*/}
                </>
              )}
            </Box>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              {user ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0, ml: 2 }}
                    >
                      <Avatar
                        alt={user.getUsername()}
                        src={profilePhoto?.url}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleMyProfile}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        My Profile
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        Post Travel Content
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        textAlign="center"
                      >
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleOpenSignUp}>
                    <Typography variant="subtitle1" textAlign="center">
                      Sign up
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleOpenLogin}>
                    <Typography variant="subtitle1" textAlign="center">
                      Login
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {openSignUp && <SignUpModal />}
      {openLogin && <LoginModal />}
      {openForgotPassword && <ForgotPassowrd />}
    </>
  )
}

export default NavBarTop

import client from '../gql/clients/apollo-client'
import { DELETE_COMMENT, INSERT_COMMENT } from '../gql/mutations/comments'
import { IComment } from '../types'
import removeDashes from '../utils/removeDashes'

export default {
  addComment: async (
    entityId: string,
    userId: string,
    entityType: string,
    entityTypeId: string,
    comment: string
  ): Promise<IComment> => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = await client.mutate({
      mutation: INSERT_COMMENT,
      variables: { path, userId, entityTypeId, comment },
    })

    const commentReturn = result.data.insert_comment_one
    return commentReturn
  },
  deleteComment: async (id: string): Promise<void> => {
    await client.mutate({
      mutation: DELETE_COMMENT,
      variables: { id },
    })
  },
}

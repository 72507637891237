import { gql } from '@apollo/client'
import { FOLLOWS_FRAGMENT } from '../fragments/follows'
import { USER_FRAGMENT, PROFILE_INFO_FRAGMENT } from '../fragments/profile'

const GET_USERS = gql`
  query GetUsers {
    user {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

const GET_USER = gql`
  query GetUser($id: uuid!, $path: ltree) {
    user_by_pk(id: $id) {
      ...User
    }
    profilesFollowing: follow(
      where: { user_id: { _eq: $id }, path: { _gte: "USER" } }
    ) {
      ...Follows
    }
    travatarsFollowing: follow(
      where: { user_id: { _eq: $id }, path: { _gte: "TRAVATAR" } }
    ) {
      ...Follows
    }
    followers: follow(where: { path: { _eq: $path } }) {
      ...Follows
    }
  }
  ${USER_FRAGMENT}
  ${FOLLOWS_FRAGMENT}
`

const GET_FOLLOWING_USERS = gql`
  query getFollowingUsers($ids: [uuid!]!) {
    user(where: { id: { _in: $ids } }) {
      ...ProfileInfo
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`
const GET_FOLLOWERS_USERS = gql`
  query getFollowersUsers($ids: [uuid!]!) {
    user(where: { id: { _in: $ids } }) {
      ...ProfileInfo
    }
  }
  ${PROFILE_INFO_FRAGMENT}
`
export { GET_USER, GET_USERS, GET_FOLLOWING_USERS, GET_FOLLOWERS_USERS }

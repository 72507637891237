import { gql } from '@apollo/client'

const TRAVEL_ELEMENT_CONTENT_FRAGMENT = gql`
  fragment TravelElementContent on travel_element_content {
    id
    entityType: entity_type {
      id
      name
    }
    location
    metadata
    likesAmount: computed_likes
    isPrivate: is_private
    path
    travelElementId: travel_element_id
    travelElement: travel_element {
      id
    }
  }
`

export { TRAVEL_ELEMENT_CONTENT_FRAGMENT }

import { getMentions, getTags } from '.'
import { ITripCard } from '../types'

const formatTripCardItineraryMetadata = (itinerary) => {
  const metadata = Object.keys(itinerary)
    .map((dayNumber) => {
      const cards = itinerary[dayNumber].map((card) => {
        return {
          itinerary: { day: Number(dayNumber), order: card.order },
          description: card?.description,
          travelElementId: card?.travelElementId,
          tags: getTags(card.description),
          mentions: getMentions(card.description),
        }
      })
      return cards
    })
    .flat()
  return metadata
}

const formatItinerary = (tripCards: ITripCard[]): any => {
  const itinerary = tripCards?.reduce((acc, tc) => {
    ;(acc[tc.metadata.itinerary.day] =
      acc[tc.metadata.itinerary.day] || []).push({
      id: tc.id,
      order: tc.metadata.itinerary.order,
      description: tc.metadata.description,
      travelElementId: tc.travelElementId,
    })
    return acc
  }, {})

  if (itinerary) {
    for (const day of Object.keys(itinerary)) {
      itinerary[day].sort((a, b) => a.order - b.order)
    }
  }

  return itinerary || {}
}

export { formatTripCardItineraryMetadata, formatItinerary }

import { gql } from '@apollo/client'

const TRAVELOG_FRAGMENT = gql`
  fragment Travelog on travelog {
    id
    name
    coverPhoto: cover_photo
    description
    tripPlanId: trip_plan_id
    tripPlan: trip_plan {
      travatar {
        id
        name
        user {
          id
          name
          profilePhoto: profile_photo
        }
      }
    }
  }
`

export { TRAVELOG_FRAGMENT }

import { gql } from '@apollo/client'
import { TRIP_CARD_CONTENT_FRAGMENT } from '../fragments/tripCardContent'

const GET_PROFILE_LIKED_TRIP_CARD_CONTENTS = gql`
  query GetProfileLikedTravelContents($travelContentIds: [uuid!]) {
    trip_card_content(where: { id: { _in: $travelContentIds } }) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

const GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID = gql`
  query GetAllTravelContentByTripPlanId($tripPlanId: uuid!) {
    trip_card_content(
      where: { trip_card: { trip_plan_id: { _eq: $tripPlanId } } }
    ) {
      ...TripCardContent
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

export {
  GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
  GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID,
}

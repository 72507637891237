import { gql } from '@apollo/client'
import { TRIP_PLAN_FRAGMENT } from '../fragments/tripPlan'

const GET_TRIP_PLAN_BY_PK = gql`
  query GetTripPlanByPk($tripPlanId: uuid!) {
    trip_plan_by_pk(id: $tripPlanId) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`

export { GET_TRIP_PLAN_BY_PK }

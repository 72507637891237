import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from '../fragments/comment'

const INSERT_COMMENT = gql`
  mutation insertComment(
    $path: ltree!
    $comment: String!
    $userId: uuid!
    $entityTypeId: uuid!
  ) {
    insert_comment_one(
      object: {
        path: $path
        comment: $comment
        user_id: $userId
        entity_type_id: $entityTypeId
      }
    ) {
      ...Comments
    }
  }
  ${COMMENT_FRAGMENT}
`

const DELETE_COMMENT = gql`
  mutation deleteComment($id: uuid!) {
    delete_comment(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export { INSERT_COMMENT, DELETE_COMMENT }

import { nanoid } from 'nanoid'
import { StoreSlice } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'
import likeService from '../services/likeService'
import travelElementService from '../services/travelElementService'
import tripCardContentService from '../services/tripCardContentService'
import tripCardService from '../services/tripCardService'
import tripPlanService from '../services/tripPlanService'
import {
  IFileWithLocation,
  ITravelElement,
  ITripCard,
  ITripCardContent,
  ITripPlan,
} from '../types'
import { formatTripCardContentByDays } from '../utils/travelContent'
import { formatItinerary } from '../utils/tripPlan'

type TripPlanSliceState = {
  tripPlan: ITripPlan | null
  tripPlans: ITripPlan[] | []
  itinerary: any
  deletedCards: string[]
  //Tip Card Content
  tripCardContent: ITripCardContent | null
  tripCardContents: ITripCardContent[] | []
  tripCardContentsPublicAndPrivate: any
  itineraryTravelElements: ITravelElement[]
}

type TripPlanSliceActions = {
  getOrAssignNewTripPlan(tripPlanId: string): void
  getTripPlan(tripPlanId: string): void
  setTripPlan(tripPlan: ITripPlan): void
  setItinerary(itinerary: any): void
  createTripPlan(travatarId: string, data: any, userId: string): void
  editTripPlan(newTripPlanInfo: ITripPlan): void
  addDeletedCard(tripCardId: string): void
  cleanItinerary(): void
  cleanTripPlan(): void
  //Trip cards content functions
  setTripPlanContents(travelContents: ITripCardContent[]): void
  getTripCardContentPublicAndPrivate(tripPlanId: string): void
  removeTripCardContent(travelContents: string): void
  insertTripCardContent(files: IFileWithLocation[], tripCardId: string): void
  editTripCardContent(travelContent: ITripCardContent): void
  addTripCardContentLike(travelContentId, profileId): Promise<void>
  deleteTripCardContentLike(travelContentId, profileId): Promise<void>
  addTripPlanTravelElement(travelElement: ITravelElement): void
  setTripPlanTravelElements(travelElementsIds: string[]): void
  addTripCardContentComment(
    tripCardContentId: string,
    comment: string,
    userId: string
  ): Promise<void>
  deleteTripCardContentComment(
    commentId: string,
    tripCardContentId: string
  ): void
}

export type TripPlanSlice = TripPlanSliceState & TripPlanSliceActions

const defaultTripPlanSliceState: TripPlanSliceState = {
  tripPlan: null,
  tripPlans: [],
  itinerary: {
    1: [{ order: 0, description: '', draggableId: nanoid() }],
  },
  deletedCards: [],
  //Default content
  tripCardContent: null,
  tripCardContents: [],
  tripCardContentsPublicAndPrivate: [],
  itineraryTravelElements: [],
}

export const createTripPlanSlice: StoreSlice<TripPlanSlice> = (set, get) => ({
  ...defaultTripPlanSliceState,

  getOrAssignNewTripPlan: async (tripPlanId) => {
    try {
      get().setStartLoading()
      const profileTripPlans = get().profileTripPlans

      let tripPlan = profileTripPlans.find(
        (tp: ITripPlan) => tp.id === tripPlanId
      )

      if (!tripPlan) {
        tripPlan = await tripPlanService.getOrAssignNewTripPlan(tripPlanId)
      }
      const travelElementsOnTripPlan = tripPlan?.tripCards?.filter(
        (tc) => tc?.travelElementId
      )

      const travelElementsIds = travelElementsOnTripPlan?.map((tc) => {
        return tc?.travelElementId
      })

      get().setTripPlanTravelElements(travelElementsIds || [])
      get().setTripPlan(tripPlan)
      get().setEndLoading()
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    }
  },
  getTripPlan: async (tripPlanId) => {
    try {
      get().setStartLoading()
      const profileTripPlans = get().profileTripPlans

      let tripPlan = profileTripPlans.find(
        (tp: ITripPlan) => tp.id === tripPlanId
      )

      if (!tripPlan) {
        tripPlan = await tripPlanService.getTripPlan(tripPlanId)
      }
      const travelElementsOnTripPlan = tripPlan?.tripCards?.filter(
        (tc) => tc?.travelElementId
      )

      const travelElementsIds = travelElementsOnTripPlan?.map((tc) => {
        return tc?.travelElementId
      })

      get().setTripPlanTravelElements(travelElementsIds)
      get().setTripPlan(tripPlan)
      get().setEndLoading()
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        console.log(error)
      }
    }
  },
  setTripPlan: (tripPlan) => {
    const itinerary = formatItinerary(tripPlan.tripCards)
    get().setItinerary(itinerary)

    set({
      tripPlan,
    })
  },
  setItinerary: (itinerary) => {
    set({ itinerary })
  },

  createTripPlan: async (travatarId, data) => {
    get().setStartLoading({ tripPlan: { mutation: true } })
    const tripPlans = get().profileTripPlans
    const itinerary = get().itinerary
    const newTripPlan = await tripPlanService.createTripPlan(
      travatarId,
      data.title,
      data.description
    )
    const tripCards = await tripCardService.insertTripCards(
      itinerary,
      newTripPlan.id
    )

    newTripPlan.tripCards = tripCards
    const profileTripPlans = [newTripPlan, ...tripPlans]

    get().setAlertMessage('The Trip Plan was successfully created', 'success')
    get().setEndLoading()
    set({
      tripPlan: newTripPlan,
      profileTripPlans,
    })
  },
  editTripPlan: async (newTripPlanInfo: ITripPlan) => {
    try {
      get().setStartLoading({ tripPlan: { mutation: true } })

      const tripPlan = await tripPlanService.editTripPlan(newTripPlanInfo)

      const tripPlansProfile = get().profileTripPlans.filter(
        (tp: ITripPlan) => tp.id !== tripPlan.id
      )

      const profileTripPlans = [tripPlan, ...tripPlansProfile]

      const tripCardsToDelete = get().deletedCards

      if (tripCardsToDelete.length) {
        await tripCardService.removeTripCards(tripCardsToDelete)
        tripPlan.tripCards = tripPlan.tripCards.filter(
          (tc) => !tripCardsToDelete.includes(tc.id)
        )
      }
      const deletedCards = []

      const itinerary = get().itinerary

      await tripCardService.editTripCards(itinerary)

      const newTripCards = await tripCardService.insertNewTripCards(
        itinerary,
        tripPlan.id
      )

      if (newTripCards.length) {
        tripPlan.tripCards = [...tripPlan.tripCards, ...newTripCards]
      }

      get().setEndLoading()
      get().setAlertMessage('Your trip plan has been modified', 'success')

      set({ deletedCards, profileTripPlans })
      get().setTripPlan(tripPlan)
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
        get().setEndLoading()
        console.log(error)
      }
    }
  },
  addDeletedCard: (tripCardId) => {
    if (tripCardId) {
      const deletedCards = [...get().deletedCards, tripCardId]
      set({
        deletedCards,
      })
    }
  },
  cleanItinerary: () => {
    set({
      itinerary: {
        1: [{ order: 0, description: '', draggableId: nanoid() }],
      },
    })
  },
  cleanTripPlan: () => {
    set({
      tripPlan: null,
    })
  },
  setTripPlanContents: (tripCardContents) => {
    set({
      tripCardContents,
    })
  },
  getTripCardContentPublicAndPrivate: async (tripPlanId) => {
    const tripCardContentsPublicAndPrivate =
      await tripCardContentService.getPublicAndPrivates(tripPlanId)
    set({ tripCardContentsPublicAndPrivate })
  },
  removeTripCardContent: async (travelContentId) => {
    try {
      await tripCardContentService.removeTripCardContents(travelContentId)

      const profileTripPlans = get().profileTripPlans.map((tp: ITripPlan) => {
        const tripCards = tp.tripCards.map((tc) => {
          const tripCardContents = tc.tripCardContents.filter(
            (tc) => tc.id !== travelContentId
          )
          return { ...tc, tripCardContents }
        })
        return { ...tp, tripCards }
      })

      const travelContents = Object.values(
        get().tripCardContentsPublicAndPrivate
      )
        .flat()
        .filter((tc: ITripCardContent) => tc.id !== travelContentId) as unknown
      const tripCardContentsPublicAndPrivate = formatTripCardContentByDays(
        travelContents as ITripCardContent[]
      )

      get().setAlertMessage(
        'The travel content was deleted succesfully',
        'success'
      )
      set({
        profileTripPlans,
        tripCardContentsPublicAndPrivate,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  insertTripCardContent: async (
    files: IFileWithLocation[],
    tripCardId: string
  ) => {
    try {
      const tripCardContents = await tripCardContentService.insertToTripCard(
        //insert to tripCard
        files,
        tripCardId
      )

      const tripPlan = get().tripPlan
      const tripCards = tripPlan.tripCards
      tripCards.map((tc) => {
        if (tc.id === tripCardId) {
          tc.tripCardContents = [...tc.tripCardContents, ...tripCardContents]
        }
      })
      tripPlan.tripCards = tripCards
      get().setTripPlan(tripPlan)

      get().setAlertMessage('Images uploaded successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  editTripCardContent: async (tripCardContent: ITripCardContent) => {
    try {
      const tripCardContentUpdated =
        await tripCardContentService.editTripCardContent(tripCardContent)

      const profileTripPlans = get().profileTripPlans.map((tp: ITripPlan) => {
        const tripCards = tp.tripCards.map((tc) => {
          const tripCardContents = tc.tripCardContents.map((tc) => {
            if (tc.id === tripCardContentUpdated.id) {
              return tripCardContentUpdated
            }
            return tc
          })
          return { ...tc, tripCardContents }
        })
        return { ...tp, tripCards }
      })

      set({
        profileTripPlans,
      })

      get().setAlertMessage('Media updated successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTripCardContentLike: async (tripCardContentId, profileId) => {
    const tripPlan = get().tripPlan

    const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
      const tripCardContents = tc?.tripCardContents.map((tcc) => {
        if (tcc.id === tripCardContentId) {
          return {
            ...tcc,
            likesAmount: tcc.likesAmount + 1,
            iLiked: true,
          }
        }
        return tcc
      })
      return {
        ...tc,
        tripCardContents,
      }
    })

    tripPlan.tripCards = tripCards
    const profileTripPlans = get().profileTripPlans.map((tp) => {
      if (tp.id === tripPlan.id) {
        return {
          ...tp,
          tripCards,
        }
      }
      return tp
    })

    likeService.addLike(
      tripCardContentId,
      profileId,
      EntityTypeEnum.TRIP_CARD_CONTENT,
      IdsEnum.ENTITY_TYPE_TRIP_CARD_CONTENT
    )

    set({
      tripPlan,
      profileTripPlans,
    })
  },
  deleteTripCardContentLike: async (tripCardContentId, profileId) => {
    const tripPlan = get().tripPlan

    const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
      const tripCardContents = tc?.tripCardContents.map((tcc) => {
        if (tcc.id === tripCardContentId) {
          return {
            ...tcc,
            likesAmount: tcc.likesAmount - 1,
            iLiked: false,
          }
        }
        return tcc
      })
      return {
        ...tc,
        tripCardContents,
      }
    })

    tripPlan.tripCards = tripCards
    const profileTripPlans = get().profileTripPlans.map((tp) => {
      if (tp.id === tripPlan.id) {
        return {
          ...tp,
          tripCards,
        }
      }
      return tp
    })

    likeService.deleteLike(
      tripCardContentId,
      profileId,
      EntityTypeEnum.TRIP_CARD_CONTENT
    )

    set({
      tripPlan,
      profileTripPlans,
    })
  },
  addTripPlanTravelElement: async (travelElement: ITravelElement) => {
    const oldTripPlanTravelElements = get().itineraryTravelElements
    const itineraryTravelElements = [
      ...oldTripPlanTravelElements,
      travelElement,
    ]
    set({
      itineraryTravelElements,
    })
  },
  setTripPlanTravelElements: async (travelElementsIds: string[]) => {
    const itineraryTravelElements =
      await travelElementService.getTravelElements(travelElementsIds)

    set({
      itineraryTravelElements,
    })
  },
  addTripCardContentComment: async (
    tripCardContentId: string,
    comment: string,
    userId: string
  ) => {
    const tripPlan = get().tripPlan

    const newComment = await commentService.addComment(
      tripCardContentId,
      userId,
      EntityTypeEnum.TRIP_CARD_CONTENT,
      IdsEnum.ENTITY_TYPE_TRIP_CARD_CONTENT,
      comment
    )

    const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
      const tripCardContents = tc?.tripCardContents.map((tcc) => {
        if (tcc.id === tripCardContentId) {
          return {
            ...tcc,
            comments: [...tcc.comments, newComment],
          }
        }
        return tcc
      })
      return {
        ...tc,
        tripCardContents,
      }
    })
    tripPlan.tripCards = tripCards

    const profileTripPlans = get().profileTripPlans.map((tp) => {
      if (tp.id === tripPlan.id) {
        return {
          ...tp,
          tripCards,
        }
      }
      return tp
    })

    set({
      tripPlan,
      profileTripPlans,
    })
  },
  deleteTripCardContentComment: (
    commentId: string,
    tripCardContentId: string
  ) => {
    const tripPlan = get().tripPlan

    const tripCards = tripPlan.tripCards?.map((tc: ITripCard) => {
      const tripCardContents = tc?.tripCardContents.map((tcc) => {
        if (tcc.id === tripCardContentId) {
          return {
            ...tcc,
            comments: tcc.comments.filter(
              (comment) => comment.id !== commentId
            ),
          }
        }
        return tcc
      })
      return {
        ...tc,
        tripCardContents,
      }
    })

    tripPlan.tripCards = tripCards
    const profileTripPlans = get().profileTripPlans.map((tp) => {
      if (tp.id === tripPlan.id) {
        return {
          ...tp,
          tripCards,
        }
      }
      return tp
    })

    set({
      tripPlan,
      profileTripPlans,
    })
  },
})

import { gql } from '@apollo/client'
import { FOLLOWS_FRAGMENT } from '../fragments/follows'

const GET_FOLLOWS_BY_PATH = gql`
  query getUserFollower($path: ltree!) {
    follow(where: { path: { _eq: $path } }) {
      ...Follows
    }
  }
  ${FOLLOWS_FRAGMENT}
`
export { GET_FOLLOWS_BY_PATH }

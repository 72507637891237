import { useState } from 'react'
import styled from '@emotion/styled'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import SearchBox from './SearchBox'
import CustomHits from './CustomHits'

const AutoCompleteContainer = styled.div`
  max-width: 328px;
  width: 100%;
  position: relative;
  & .MuiAutocomplete-popupIndicator {
    transform: rotate(0deg) !important ;
  }
`

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

export default function ExplorerSearch() {
  const [hits, setHits] = useState<string[]>([])
  return (
    <AutoCompleteContainer className="searcher">
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}
      >
        <SearchBox hits={hits} />
        <CustomHits setHits={setHits} />
      </InstantSearch>
    </AutoCompleteContainer>
  )
}

function format(value, pattern) {
  let i = 0
  const v = value?.toString()
  return pattern.replace(/#/g, () => v[i++])
}

export default function uuidFormater(id) {
  const newId = format(id, '########-####-####-####-############')
  return newId
}

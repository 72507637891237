import { gql } from '@apollo/client'

const LIKES_FRAGMENT = gql`
  fragment Likes on like {
    id
    path
    userId: user_id
    entityType: entity_type {
      id
      name
    }
  }
`
export { LIKES_FRAGMENT }

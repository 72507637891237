import { gql } from '@apollo/client'
import { TRAVELOG_FRAGMENT } from '../fragments/travelog'

const INSERT_TRAVELOG = gql`
  mutation InsertTravelog(
    $name: String
    $description: String
    $tripPlanId: uuid!
    $entityTypeId: uuid!
    $coverPhoto: String
  ) {
    insert_travelog_one(
      object: {
        name: $name
        description: $description
        trip_plan_id: $tripPlanId
        entity_type_id: $entityTypeId
        cover_photo: $coverPhoto
      }
    ) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

const UPDATE_TRAVELOG = gql`
  mutation UpdateTravelog(
    $name: String
    $description: String
    $id: uuid!
    $coverPhoto: String
  ) {
    update_travelog_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description, cover_photo: $coverPhoto }
    ) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

export { INSERT_TRAVELOG, UPDATE_TRAVELOG }

import client from '../gql/clients/apollo-client'
import { REMOVE_TRAVEL_ELEMENT_CONTENTS } from '../gql/mutations/travelElementContent'

export default {
  /*getLikedTripCardContents: async (tripCardContentIds: string[]) => {
    const result = await client.query({
      query: GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
      variables: {
        tripCardContentIds,
      },
    })
    let travelContents = result?.data?.travel_content
    travelContents = Promise.all(
      travelContents.map(async (te) => {
        const travelContentUrl = await getTripCardContentUrl(te.id, te.path)
        const profileName = te.travelog?.travatar?.user?.name
        const profileId = te.travelog?.travatar?.user?.id
        const profileCover = te.travelog?.travatar?.user?.profile_photo
        const profileCoverUrl = await getProfilePhotoUrl(
          profileCover,
          profileId
        )

        return {
          ...te,
          travelContentUrl,
          profileInfo: {
            name: profileName,
            photoUrl: profileCoverUrl,
          },
        }
      })
    )

    return travelContents
  },
  insertTripCardContentsToTripCard: async (
    filesWithLocation: IFileWithLocation[],
    tripCardId: string
  ): Promise<ITripCardContent[]> => {
    const travelContentInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          trip_card_id: tripCardId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
        }
      }
    )
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_CONTENTS,
      variables: { travelContents: travelContentInsertInput },
    })
    const travelContents = result.data.insert_travel_content.returning

    const travelContentsWithUrl = await Promise.all(
      travelContents.map(async (tc, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tc.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)
        return {
          ...tc,
          travelContentUrl: url,
        }
      })
    )

    return travelContentsWithUrl
  },
  editTripCardContent: async (
    travelContent: ITripCardContent
  ): Promise<ITripCardContent> => {
    travelContent.metadata = {
      ...travelContent.metadata,
      tags: getTags(travelContent.metadata.description),
      mentions: getMentions(travelContent.metadata.description),
    }
    const result = await client.mutate({
      mutation: UPDATE_TRAVEL_CONTENT,
      variables: {
        id: travelContent.id,
        metadata: travelContent.metadata,
        location: travelContent.location,
        isPrivate: travelContent.isPrivate,
      },
    })
    const travelContentUpdated = result.data.update_travel_content_by_pk
    return travelContentUpdated
  },

  insertUrlToTripCardContents: async (tripCardContents: ITripCardContent[]) => {
    return await Promise.all(
      tripCardContents.map(async (tc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tc.id, tc.path)
        return {
          ...tc,
          tripCardContentUrl,
        }
      })
    )
  },*/
  removeTravelElementContents: async (travelElementContentIds: string[]) => {
    try {
      const result = await client.mutate({
        mutation: REMOVE_TRAVEL_ELEMENT_CONTENTS,
        variables: { travelElementContentIds },
      })

      const deletedTravelElementContents =
        result?.data?.delete_travel_element_content?.returning

      if (
        deletedTravelElementContents.length === travelElementContentIds.length
      ) {
        return deletedTravelElementContents
      } else {
        throw new Error(
          'There was an error deleted some travel element content'
        )
      }
    } catch {
      throw new Error('Trip Content could not be deleted')
    }
  },
}

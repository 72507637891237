import { StoreSlice } from '.'
import travatarService from '../services/travatarService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import likeService from '../services/likeService'
import followService from '../services/followService'
import profileService from '../services/profileService'

import { IProfile, ITravatar } from '../types'
import InvalidUuidError from '../customErrors/InvalidUuid.error'

type TravatarSliceState = {
  travatar: ITravatar | null
  travatars: ITravatar[] | []
  travatarFollowerProfiles: IProfile[] | []
}

type TravatarSliceActions = {
  getTravatar(id: string, userId: string): Promise<void>
  newTravatar(newPhoto: any, newTravatar: ITravatar)
  editTravatar(newPhoto: any, newTravatarData: ITravatar)
  addTravatarLike(travatarId: string, profileId: string): Promise<void>
  deleteTravatarLike(travatarId: string, profileId: string): Promise<void>
  insertTravatarFollow(travatarId: string, profileId: string): Promise<void>
  deleteTravatarFollow(travatarId: string, profileId: string): Promise<void>
  getTravatarFollowers(): Promise<void>
  deleteTravatar(travatarId: string, profileId: string): Promise<void>
}

export type TravatarSlice = TravatarSliceState & TravatarSliceActions

const defaultTravatarSliceState: TravatarSliceState = {
  travatar: null,
  travatars: [],
  travatarFollowerProfiles: [],
}

export const createTravatarSlice: StoreSlice<TravatarSlice> = (set, get) => ({
  ...defaultTravatarSliceState,

  getTravatar: async (travatarId: string) => {
    get().setStartLoading()

    try {
      const travatars: ITravatar[] = get().travatars

      let travatar = travatars.find((t: ITravatar) => t.id === travatarId)

      if (!travatar) {
        travatar = await travatarService.getTravatar(travatarId)
        travatars.push(travatar)
      }

      get().setEndLoading()
      set({
        travatar,
        travatars,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  newTravatar: async (newPhoto, newTravatarData: ITravatar) => {
    get().setStartLoading({ travatar: { mutation: true } })
    const currentProfileTravatars = get().profileTravatars

    const newTravatar = await travatarService.newTravatar(
      newPhoto,
      newTravatarData
    )

    const profileTravatars = [newTravatar, ...currentProfileTravatars]

    get().setEndLoading()
    set({
      profileTravatars,
    })
  },
  editTravatar: async (newPhoto, newTravatarData: ITravatar) => {
    get().setStartLoading({ travatar: { mutation: true } })

    try {
      const travatars = get().profileTravatars

      const travatar = await travatarService.editTravatar(
        newPhoto,
        newTravatarData
      )

      const filterTravatars = travatars.filter(
        (travatar: ITravatar) => travatar.id !== newTravatarData.id
      )
      const profileTravatars = [travatar, ...filterTravatars]

      get().setEndLoading()
      set({
        profileTravatars,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  addTravatarLike: async (travatarId: string, profileId: string) => {
    const travatar = get().travatar

    travatar.likes.push(profileId)

    likeService.addLike(
      travatarId,
      profileId,
      EntityTypeEnum.TRAVATAR,
      IdsEnum.ENTITY_TYPE_TRAVATAR
    )

    set({
      travatar,
    })
  },
  deleteTravatarLike: async (travatarId: string, profileId: string) => {
    const travatar = get().travatar

    travatar.likes = travatar.likes.filter((like) => like !== profileId)

    likeService.deleteLike(travatarId, profileId, EntityTypeEnum.TRAVATAR)

    set({
      travatar,
    })
  },
  insertTravatarFollow: async (travatarId: string, profileId: string) => {
    try {
      const travatar = get().travatar
      const oldtravatars = get().travatars

      await followService.addFollow(
        travatarId,
        profileId,
        EntityTypeEnum.TRAVATAR,
        IdsEnum.ENTITY_TYPE_TRAVATAR
      )

      travatar.followers.push(profileId)

      const travatars = oldtravatars.filter(
        (t: ITravatar) => t.id !== travatar.id
      )

      travatars.push(travatar)

      set({
        travatar,
        travatars,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  deleteTravatarFollow: async (travatarId: string, profileId: string) => {
    try {
      const travatarRetrieve = get().travatar
      let travatarsRetrieve: ITravatar[] = get().travatars

      await followService.deleteFollow(
        travatarId,
        profileId,
        EntityTypeEnum.TRAVATAR
      )

      travatarsRetrieve = travatarsRetrieve.filter(
        (t: ITravatar) => t.id !== travatarId
      )

      travatarRetrieve.followers = travatarRetrieve.followers.filter(
        (follower) => follower != profileId
      )
      travatarsRetrieve.push(travatarRetrieve)

      set({
        travatar: travatarRetrieve,
        travatars: travatarsRetrieve,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  getTravatarFollowers: async () => {
    const followers = get().travatar.followers
    const travatarFollowerProfiles = await profileService.getFollowersProfiles(
      followers
    )

    set({
      travatarFollowerProfiles,
    })
  },
  deleteTravatar: async (travatarId: string) => {
    try {
      await travatarService.deleteTravatar(travatarId)

      const travatars = get().profileTravatars
      const profileTravatars = travatars.filter(
        (travatar: ITravatar) => travatar.id !== travatarId
      )

      get().setAlertMessage('Travatar deleted successfully', 'success')
      set({ profileTravatars })
    } catch (err) {
      if (err.message.includes('Foreign key violation'))
        get().setAlertMessage(
          'Travatar could not be deleted because it has Travelogs or Trip Plans',
          'error'
        )
      else {
        get().setAlertMessage(err.message, 'error')
        console.log(err)
      }
    }
  },
})

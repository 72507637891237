import { useEffect } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { SearchState, SearchResults } from 'react-instantsearch-core'

interface IProps {
  searchState: SearchState
  searchResults: SearchResults
  setHits: (string) => void
}

function Hits({ searchState, searchResults, setHits }: IProps) {
  const validQuery = searchState.query?.length >= 3

  useEffect(() => {
    if (searchResults?.hits.length > 0 && validQuery) {
      setHits(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        searchResults?.hits.map((hit) => hit?.title || hit?.name) || []
      )
    } else {
      setHits([])
    }
  }, [searchResults])

  return <></>
}

export default connectStateResults(Hits)

import { gql } from '@apollo/client'

const FOLLOWS_FRAGMENT = gql`
  fragment Follows on follow {
    id
    path
    userId: user_id
    entityType: entity_type {
      id
      name
    }
  }
`
export { FOLLOWS_FRAGMENT }

import { StoreSlice } from '.'
import profileService from '../services/profileService'
import travatarService from '../services/travatarService'
import travelogService from '../services/travelogService'
import followService from '../services/followService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'

import formatUuid from '../utils/formatUuid'

import {
  IProfile,
  ITravatar,
  ITripPlan,
  ITravelog,
  ILike,
  ITripCardContent,
  IProfileInfo,
  ITravelElement,
} from '../types'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import travelElementService from '../services/travelElementService'
import tripCardContentService from '../services/tripCardContentService'
import tripPlanService from '../services/tripPlanService'

type ProfileSliceState = {
  profile: IProfile | null
  profileFollowers: string[] | []
  profileFollowing: string[] | []
  travatarFollowing: string[] | []
  profileTravatars: ITravatar[] | []
  profileTripPlans: ITripPlan[] | []
  profileTravelogs: ITravelog[] | []
  profileTravelElements: ITravelElement[] | []
  profileLikedTravelogs: ITravelog[] | []
  profileLikedVideos: ITripCardContent[] | []
  profileLikedImages: ITripCardContent[] | []
  profileLikedTravelElements: ITravelElement[] | []
  profileFollowerProfiles: IProfileInfo[] | []
  profileFollowingProfiles: IProfileInfo[] | []
  profileFollowingTravatars: ITravatar[] | []
}

type ProfileSliceActions = {
  getProfile(profileId: string): Promise<void>
  cleanProfile(): void
  getProfileLikedTravelogs(travelogsLiked: ILike[]): Promise<void>
  getProfileLikedTravelElements(
    travelElementsLiked: ILike[],
    profileId: string
  ): Promise<void>
  getProfileLikedVideos(imageLiked: ILike[]): Promise<void>
  getProfileLikedImages(imageLiked: ILike[]): Promise<void>
  editProfile(newProfileInfo, filePhoto: File): Promise<void>
  getProfileFollowers(): Promise<void>
  getProfileFollowings(): Promise<void>
  getFollowingTravatars(): Promise<void>
  insertProfileFollow(profileToFollowId, profileId): Promise<void>
  deleteProfileFollow(profileToUnfollowId, profileId): Promise<void>
}

export type ProfileSlice = ProfileSliceState & ProfileSliceActions

const defaultProfileSliceState: ProfileSliceState = {
  profile: null,
  profileFollowers: [],
  profileFollowing: [],
  travatarFollowing: [],
  profileTravatars: [],
  profileTravelogs: [],
  profileTravelElements: [],
  profileTripPlans: [],
  profileLikedTravelogs: [],
  profileLikedTravelElements: [],
  profileLikedVideos: [],
  profileLikedImages: [],
  profileFollowerProfiles: [],
  profileFollowingProfiles: [],
  profileFollowingTravatars: [],
}
export const createProfileSlice: StoreSlice<ProfileSlice> = (set, get) => ({
  ...defaultProfileSliceState,

  cleanProfile: () => {
    set({ ...defaultProfileSliceState })
  },
  getProfile: async (profileId) => {
    get().setStartLoading()
    try {
      const profileInStore = get().profile
      if (!profileInStore?.id || profileInStore?.id !== profileId) {
        const {
          profile,
          followers: profileFollowers,
          profileFollowing,
          travatarFollowing,
        } = await profileService.getProfile(profileId)

        const profileTravatars = await travatarService.fetchCoverImages(
          profile?.travatars
        )

        const tripPlans = profile?.travatars
          ?.map((travatar) => travatar?.tripPlans)
          .flat()

        const profileTripPlans = (await tripPlanService.completeTripPlansInfo(
          tripPlans
        )) as ITripPlan[]

        const profileTravelogsArray = await Promise.all(
          profile?.travatars?.map(
            async (travatar) =>
              await Promise.all(
                travatar?.tripPlans?.map(
                  async (tp) =>
                    await travelogService.completeTravelogsInfo({
                      travelogs: tp?.travelogs,
                      profilePhotoUrl: profile.profilePhotoUrl,
                    })
                )
              )
          )
        )
        /*const profileTravelElements =
          await travelElementService.completeTravelElementsInfo(
            profile?.travatars
              ?.map((travatar) => travatar?.travelElements)
              .flat(),
            userId
          )*/

        set({
          profile,
          profileFollowers,
          profileFollowing,
          profileTravatars,
          profileTripPlans,
          profileTravelogs: profileTravelogsArray.flat(2),
          travatarFollowing,
          //profileTravelElements,
        })
      }
      get().setEndLoading()
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  editProfile: async (newProfileInfo, profilePhoto) => {
    try {
      get().setStartLoading({ profile: { edit: true } })
      const profile = await profileService.editProfile(
        newProfileInfo,
        profilePhoto
      )

      get().setEndLoading()

      set({
        profile,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
    }
  },
  getProfileLikedTravelogs: async (travelogsLiked: ILike[]) => {
    get().setStartLoading({ profile: { likedTravelogs: true } })

    const travelogIds = travelogsLiked?.map((tl) =>
      formatUuid(tl.path.split('.').pop())
    )
    const profileLikedTravelogs = await travelogService.getTravelogs(
      travelogIds
    )

    get().setEndLoading()

    set({
      profileLikedTravelogs,
    })
  },
  getProfileLikedTravelElements: async (travelElementsLiked: ILike[]) => {
    get().setStartLoading({ profile: { travelElementsLiked: true } })

    const travelElementsIds = travelElementsLiked?.map((travelElement) =>
      formatUuid(travelElement.path.split('.').pop())
    )

    const profileLikedTravelElements =
      await travelElementService.getTravelElements(travelElementsIds)

    get().setEndLoading()
    set({
      profileLikedTravelElements,
    })
  },
  getProfileLikedVideos: async (videosLiked: ILike[]) => {
    get().setStartLoading({ profile: { likedVideos: true } })

    const videosIds = videosLiked?.map((video) =>
      formatUuid(video.path.split('.').pop())
    )
    const profileLikedVideos =
      await tripCardContentService.getLikedTripCardContents(videosIds)

    get().setEndLoading()

    set({
      profileLikedVideos,
    })
  },
  getProfileLikedImages: async (imageLiked: ILike[]) => {
    get().setStartLoading({ profile: { likedImages: true } })

    const imagesIds = imageLiked?.map((image) =>
      formatUuid(image.path.split('.').pop())
    )
    const profileLikedImages =
      await tripCardContentService.getLikedTripCardContents(imagesIds || [])

    get().setEndLoading()

    set({
      profileLikedImages,
    })
  },
  getProfileFollowers: async () => {
    get().setStartLoading({ profile: { followers: true } })
    const profileFollowerProfiles = await profileService.getFollowersProfiles(
      get().profileFollowers
    )

    get().setEndLoading()

    set({
      profileFollowerProfiles,
    })
  },
  getProfileFollowings: async () => {
    get().setStartLoading({ profile: { followers: true } })
    const profileFollowingProfiles = await profileService.getFollowingProfiles(
      get().profileFollowing
    )

    get().setEndLoading()

    set({
      profileFollowingProfiles,
    })
  },
  insertProfileFollow: async (profileToFollowId, profileId) => {
    const followers = get().profileFollowers
    const profileFollowers = [...followers, profileId]
    followService.addFollow(
      profileToFollowId,
      profileId,
      EntityTypeEnum.USER,
      IdsEnum.ENTITY_TYPE_PROFILE
    )

    set({
      profileFollowers,
    })
  },
  deleteProfileFollow: async (profileToUnfollowId, profileId) => {
    const following = get().profileFollowing

    const followingProfile = get().profileFollowerProfiles

    const followers = get().profileFollowers

    const profileFollowing = following.filter(
      (f: string) => formatUuid(f) != profileToUnfollowId
    )

    const profileFollowingP = get().profileFollowingProfiles

    const profileFollowingProfiles = profileFollowingP.filter(
      (f: IProfileInfo) => f.id != profileToUnfollowId
    )

    const profileFollowers = followers.filter((f: string) => f != profileId)

    const profileFollowerProfiles = followingProfile.filter(
      (pfp: IProfileInfo) => pfp.id != profileId
    )

    followService.deleteFollow(
      profileToUnfollowId,
      profileId,
      EntityTypeEnum.USER
    )

    set({
      profileFollowing,
      profileFollowers,
      profileFollowingProfiles,
      profileFollowerProfiles,
    })
  },
  getFollowingTravatars: async () => {
    const travatarsIds = get().travatarFollowing.map((travatar) =>
      formatUuid(travatar)
    )
    const profileFollowingTravatars = await travatarService.getTravatars(
      travatarsIds
    )
    set({
      profileFollowingTravatars,
    })
  },
})

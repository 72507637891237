import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'

const INSERT_TRAVEL_ELEMENT = gql`
  mutation InsertTravelElement(
    $cover_photo: String
    $description: String!
    $name: String!
    $travatarId: uuid!
    $location: point!
    $metadata: jsonb
    $entityTypeId: uuid!
    $userId: uuid
  ) {
    insert_travel_element_one(
      object: {
        description: $description
        location: $location
        travatar_id: $travatarId
        cover_photo: $cover_photo
        entity_type_id: $entityTypeId
        metadata: $metadata
        name: $name
        user_id: $userId
      }
    ) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

const UPDATE_TRAVEL_ELEMENT = gql`
  mutation UpdateTravelElement(
    $id: uuid!
    $coverPhoto: String
    $description: String
    $location: point
    $metadata: jsonb
    $name: String
  ) {
    update_travel_element_by_pk(
      pk_columns: { id: $id }
      _set: {
        cover_photo: $coverPhoto
        description: $description
        location: $location
        metadata: $metadata
        name: $name
      }
    ) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`

export { INSERT_TRAVEL_ELEMENT, UPDATE_TRAVEL_ELEMENT }

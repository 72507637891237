import client from '../gql/clients/apollo-client'
import { INSERT_FOLLOW, UNFOLLOW } from '../gql/mutations/follows'
import removeDashes from '../utils/removeDashes'

export default {
  addFollow: async (
    entityId: string,
    profileId: string,
    entityType: string,
    entityTypeId: string
  ) => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = client.mutate({
      mutation: INSERT_FOLLOW,
      variables: {
        path,
        userId: profileId,
        entityTypeId,
      },
    })

    return result
  },
  deleteFollow: async (
    entityId: string,
    profileId: string,
    entityType: string
  ) => {
    const path = `${entityType}.${removeDashes(entityId)}`
    const result = client.mutate({
      mutation: UNFOLLOW,
      variables: {
        path,
        userId: profileId,
      },
    })
    return result
  },
}

import { StoreSlice } from '.'
import { IBreadcrumbElement } from '../types'
type AppSliceState = {
  openSignUp: boolean
  openLogin: boolean
  openForgotPassword: boolean
  isFirstTimeProfile: boolean
  isLoading: any
  alertMessage: any
  breadcrumb: IBreadcrumbElement[]
  notFound: boolean
  mutationSuccess: boolean
}

type AppSliceActions = {
  setOpenSignUp(): void
  setOpenLogin(): void
  setOpenForgotPassword(): void
  setCloseForgotPassword(): void
  setCloseSignUp(): void
  setCloseLogin(): void
  setAlertMessage(alertMessage: string, severity: string): void
  setCloseAlert(): void
  setStartLoading(isLoading?: any): void
  setEndLoading(): void
  setBreadcrumb(breadcrumb: IBreadcrumbElement[]): void
  setFirstTimeProfile(isFirstTimeProfile: boolean): void
  setNotFound(notFound: boolean): void
  setMutationSuccess(mutationSuccess: boolean): void
}

export type AppSlice = AppSliceState & AppSliceActions

const defaultAppSliceState: AppSliceState = {
  openSignUp: false,
  openLogin: false,
  openForgotPassword: false,
  notFound: false,
  isFirstTimeProfile: false,
  isLoading: {
    main: false,
    profile: {
      likedVideos: false,
      likedTravelogs: false,
      likedPhotos: false,
      followers: false,
      edit: false,
    },
    tripPlan: { main: false, mutation: false },
    travelog: { main: false, mutation: false },
    travatar: { main: false, mutation: false },
    travelElement: { main: false, mutation: false },
  },
  alertMessage: {
    message: '',
    severity: '',
  },
  breadcrumb: [],
  mutationSuccess: false,
}

export const createAppSlice: StoreSlice<AppSlice> = (set, get) => ({
  ...defaultAppSliceState,

  setNotFound: (notFound) => {
    set({ notFound })
  },
  setOpenSignUp: () => {
    const openSignUp = true
    const openLogin = false
    const openForgotPassword = false
    set({ openSignUp, openLogin, openForgotPassword })
  },
  setOpenForgotPassword: () => {
    const openLogin = false
    const openSignUp = false
    const openForgotPassword = true
    set({ openLogin, openSignUp, openForgotPassword })
  },
  setOpenLogin: () => {
    const openSignUp = false
    const openLogin = true
    const openForgotPassword = false
    set({ openSignUp, openLogin, openForgotPassword })
  },
  setCloseSignUp: () => {
    const openSignUp = false
    set({ openSignUp })
  },
  setCloseLogin: () => {
    const openLogin = false
    set({ openLogin })
  },
  setCloseForgotPassword: () => {
    const openForgotPassword = false
    set({ openForgotPassword })
  },
  setAlertMessage: (message: string, severity: string) => {
    const alertMessage = { message, severity }
    set({ alertMessage })
  },
  setCloseAlert: () => {
    set({ alertMessage: defaultAppSliceState.alertMessage })
  },
  setStartLoading: (isLoading = { main: true }) => {
    const startLoading = { ...get().isLoading, isLoading }
    set({ isLoading: isLoading ? isLoading : startLoading })
  },
  setEndLoading: () => {
    set({ isLoading: defaultAppSliceState.isLoading })
  },
  setBreadcrumb: (breadcrumb: IBreadcrumbElement[]) => {
    set({ breadcrumb })
  },
  setFirstTimeProfile: (isFirstTimeProfile) => set({ isFirstTimeProfile }),
  setMutationSuccess: (mutationSuccess) => set({ mutationSuccess }),
})

import { gql } from '@apollo/client'
import { TRIP_PLAN_FRAGMENT } from '../fragments/tripPlan'

const NEW_TRIP_PLAN = gql`
  mutation NewTripPlan(
    $travatarId: uuid
    $title: String
    $metadata: jsonb
    $description: String
    $id: uuid
    $userId: uuid
  ) {
    insert_trip_plan_one(
      object: {
        id: $id
        title: $title
        description: $description
        metadata: $metadata
        travatar_id: $travatarId
        user_id: $userId
      }
      on_conflict: {
        constraint: trip_plan_pkey
        update_columns: [description, metadata, travatar_id, title, user_id]
      }
    ) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`
const UPDATE_TRIP_PLAN = gql`
  mutation UpdateTripPlan(
    $id: uuid!
    $title: String
    $travatarId: uuid
    $metadata: jsonb
    $description: String
  ) {
    update_trip_plan_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        description: $description
        metadata: $metadata
        travatar_id: $travatarId
      }
    ) {
      ...TripPlan
    }
  }
  ${TRIP_PLAN_FRAGMENT}
`
export { NEW_TRIP_PLAN, UPDATE_TRIP_PLAN }

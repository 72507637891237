import { gql } from '@apollo/client'
import { LIKES_FRAGMENT } from '../fragments/like'

const GET_LIKES_BY_PATH = gql`
  query getLikesByPath($path: ltree!) {
    like(where: { path: { _eq: $path } }) {
      ...Likes
    }
  }
  ${LIKES_FRAGMENT}
`

const GET_MY_LIKES = gql`
  query GetMyLikes($userId: uuid!, $paths: [ltree!]) {
    like(where: { path: { _in: $paths }, user_id: { _eq: $userId } }) {
      ...Likes
    }
  }
  ${LIKES_FRAGMENT}
`

export { GET_LIKES_BY_PATH, GET_MY_LIKES }

import { ReactElement, useState } from 'react'
import { useStore } from '../../../store'
import { useForm, SubmitHandler } from 'react-hook-form'

import Modal from '@mui/material/Modal'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { Button, CircularProgress } from '@mui/material'

type Inputs = {
  email?: string
  password?: string
  code?: string
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 434px;
  width: 100%;
  min-height: 453px;
  background-color: #1b1a29;
  border: 1px solid #5a5784;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  & .close {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
  }
  & .close svg {
    font-size: 30px;
  }
  & .form {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    max-width: 362px;
    width: 97%;
  }
  & label {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    opacity: 0.8;
  }
  & .email,
  & .password,
  & .code {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 362px;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: none;
    background-color: #32313f;
    padding: 8px 20px 9px;
    display: block;
    margin-top: 7px;
    font-family: inherit;
  }
  & .label-2 {
    margin-top: 21px;
  }
  & .email:focus,
  & .password:focus,
  & .code:focus {
    outline: #ea8942 1px solid;
    transition: all 0.3s;
  }
  input::placeholder {
    font-size: 15px;
    color: #fff;
    opacity: 0.4;
    font-family: inherit;
  }

  & input[type='submit'] {
    margin: 42px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 37px;
    border-radius: 5px;
    border: none;
    background-color: #ea8942;
    font-size: 16px;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
  }
  & input[type='submit']:hover {
    opacity: 0.8;
    transition: all 0.3s;
  }
  & .form p {
    margin: 5px 0 0;
    color: red;
    font-size: 14px;
  }
  & .disabled {
    opacity: 0.5;
  }
`

const Title = styled.h3`
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 39px 0 40px 0;
`
const TextBottom = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #fff;
  padding-bottom: 20px;
  & span {
    color: #ea8942;
    cursor: pointer;
  }
`

const PasswordContainer = styled.div`
  position: relative;
  & button {
    position: absolute;
    top: 3px;
    right: 0;
    color: #fff;
    opacity: 0.8;
  }
`
const SignUpModal = (): ReactElement => {
  const {
    openSignUp,
    setCloseSignUp,
    setOpenLogin,
    setAlertMessage,
    setFirstTimeProfile,
    isLoading,
    setStartLoading,
    setEndLoading,
  } = useStore()
  const [type, setType] = useState(false)
  const [showCode, setShowCode] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      if (showCode) {
        confirmSignUp(data)
      } else {
        await signUpWithEmailAndPassword(data)
        setShowCode(true)
        setAlertMessage(
          ' Check your email inbox, your code has been sent.',
          'info'
        )
      }
    } catch (e) {
      // TODO: Handle errors
      setAlertMessage(e.message, 'error')
      console.error('Error: ', e.message)
    }
  }

  async function signUpWithEmailAndPassword(
    data: Inputs
  ): Promise<CognitoUser> {
    const { email, password } = data
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
      return user
    } catch (error) {
      setAlertMessage(error.message, 'error')

      throw error
    }
  }

  const confirmSignUp = async (data: Inputs): Promise<void> => {
    setStartLoading()
    const { email, password, code } = data

    try {
      await Auth.confirmSignUp(email, code)
      const amplifyUser = await Auth.signIn(email, password)

      if (amplifyUser) {
        setCloseSignUp()
        setFirstTimeProfile(true)
      } else {
        setAlertMessage('Something went wrong', 'error')
        throw new Error('Something went wrong')
      }
    } catch (error) {
      if (
        error.message == 'Invalid verification code provided, please try again.'
      ) {
        setAlertMessage(error.message, 'error')
      } else console.error(error.message)
    } finally {
      setEndLoading()
    }
  }

  const handleType = (): void => setType((state) => !state)

  return (
    <Modal
      open={openSignUp}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-signup"
      onClose={setCloseSignUp}
    >
      <>
        <MainContainer>
          <IconButton onClick={setCloseSignUp} className="close">
            <CloseIcon />
          </IconButton>
          <Title>Sign-up</Title>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <label>
              Email
              <input
                {...register('email', { required: true })}
                className={`${showCode ? 'disabled' : ''} email`}
                placeholder="Enter Your Email"
                type="email"
                disabled={showCode ? true : false}
              />
            </label>
            {errors.email && <p>The Email is required</p>}
            <label className="label-2">
              Password
              <PasswordContainer>
                <input
                  {...register('password', { required: true })}
                  className={`${showCode ? 'disabled' : ''} password`}
                  placeholder="Enter Password"
                  type={type ? 'text' : 'password'}
                  disabled={showCode ? true : false}
                />
                <IconButton onClick={handleType}>
                  {type ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </PasswordContainer>
            </label>
            {errors.password && <p>The Password is required</p>}
            {showCode && (
              <>
                <label className="label-2">
                  Code
                  <input
                    {...register('code', { required: true })}
                    className="code"
                    placeholder="Enter your verification code"
                    type="text"
                  />
                </label>
                {errors.code && <p>The Code is required</p>}
              </>
            )}
            <LoadingButton
              variant="contained"
              loading={isLoading.main}
              loadingIndicator={<CircularProgress size={15} color="primary" />}
              type="submit"
              sx={{ margin: '30px 0', width: 200, alignSelf: 'center' }}
            >
              Sign Up
            </LoadingButton>
          </form>
          <TextBottom>
            Already Have an Account?
            <Button onClick={setOpenLogin}> Login here</Button>
          </TextBottom>
        </MainContainer>
      </>
    </Modal>
  )
}

export default SignUpModal

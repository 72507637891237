import { StoreSlice } from '.'
import { IComment, ITravelog } from '../types'
import travelogService from '../services/travelogService'
import likeService from '../services/likeService'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'

type TravelogSliceState = {
  travelog: ITravelog | null
}

type TravelogSliceActions = {
  getTravelog(id: string): Promise<void>
  addTravelogLike(travelogId: string, profileId: string): Promise<void>
  deleteTravelogLike(travelogId: string, profileId: string): Promise<void>
  createTravelog(travelogData: ITravelog): Promise<void>
  editTravelog(newTravelogData: ITravelog): Promise<void>
  addTravelogComment(
    comment: string,
    travelogId: string,
    userId: string
  ): Promise<void>
  deleteTravelogComment(commentId: string, travelogId: string): Promise<void>
  cleanTravelog(): void
}

export type TravelogSlice = TravelogSliceState & TravelogSliceActions

const defaultTravelogSliceState: TravelogSliceState = {
  travelog: null,
}

export const createTravelogSlice: StoreSlice<TravelogSlice> = (set, get) => ({
  ...defaultTravelogSliceState,

  getTravelog: async (travelogId) => {
    get().setStartLoading()
    try {
      let travelog = get().profileTravelogs.find(
        (travelog: ITravelog) => travelog.id === travelogId
      )
      if (!travelog) {
        travelog = await travelogService.getTravelog(travelogId)
      }
      if (!travelog) get().setNotFound(true)

      get().setEndLoading()
      set({
        travelog,
      })
    } catch (error) {
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  createTravelog: async (travelogData: ITravelog) => {
    get().setStartLoading({ travelog: { mutation: true } })
    try {
      const travelog = await travelogService.createTravelog(travelogData)

      const travelogs = get().profileTravelogs
      const profileTravelogs = [travelog, ...travelogs]

      const profileTripPlans = get().profileTripPlans.map((tp) => {
        if (tp.id === travelogData.tripPlanId) {
          const hasTravelog = true
          return {
            ...tp,
            hasTravelog,
          }
        }
        return tp
      })

      get().setAlertMessage('Travelog created', 'success')
      get().setEndLoading()
      set({ travelog, profileTravelogs, profileTripPlans })
    } catch (err) {
      get().setEndLoading()
      get().setAlertMessage(err.message, 'error')
      console.log(err)
    }
  },
  editTravelog: async (newTravelogData) => {
    get().setStartLoading({ travelog: { mutation: true } })
    try {
      const travelog = await travelogService.editTravelog(newTravelogData)

      const profileTravelogs = get().profileTravelogs.map((tg) => {
        if (travelog.id === tg.id) {
          return travelog
        }
        return tg
      })

      get().setEndLoading()
      get().setAlertMessage('Travelog edited successfully', 'success')
      set({ travelog, profileTravelogs })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
      get().setEndLoading()
    }
  },
  addTravelogLike: async (travelogId: string, profileId: string) => {
    try {
      const profileTravelogs = get().profileTravelogs.map((travelog) => {
        if (travelogId === travelog.id) {
          return {
            ...travelog,
            likes: [...travelog.likes, profileId],
          }
        }
        return travelog
      })

      const travelog = get().travelog
      travelog.likes = [...travelog.likes, profileId]

      likeService.addLike(
        travelogId,
        profileId,
        EntityTypeEnum.TRAVELOG,
        IdsEnum.ENTITY_TYPE_TRAVELOG
      )

      set({
        travelog,
        profileTravelogs,
      })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
    }
  },
  deleteTravelogLike: async (travelogId, profileId) => {
    try {
      const profileTravelogs = get().profileTravelogs.map((travelog) => {
        if (travelogId === travelog.id) {
          return {
            ...travelog,
            likes: travelog.likes.filter((id) => id !== profileId),
          }
        }
        return travelog
      })

      const travelog = get().travelog
      travelog.likes = travelog.likes.filter((like) => like !== profileId)

      likeService.deleteLike(travelogId, profileId, EntityTypeEnum.TRAVELOG)

      set({
        travelog,
        profileTravelogs,
      })
    } catch (err) {
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelogComment: async (
    comment: string,
    travelogId: string,
    userId: string
  ) => {
    const newComment = await commentService.addComment(
      travelogId,
      userId,
      EntityTypeEnum.TRAVELOG,
      IdsEnum.ENTITY_TYPE_TRAVELOG,
      comment
    )

    const travelog = get().travelog
    if (travelog) {
      const comments = [...travelog.comments, newComment]
      travelog.comments = comments
    }

    const profileTravelogs = get().profileTravelogs.map(
      (travelog: ITravelog) => {
        if (travelog.id === travelogId) {
          const comments = [...travelog.comments, newComment]
          return {
            ...travelog,
            comments,
          }
        }
        return travelog
      }
    )

    set({
      travelog,
      profileTravelogs,
    })
  },
  deleteTravelogComment: async (commentId: string, travelogId: string) => {
    await commentService.deleteComment(commentId)

    const travelog = get().travelog
    if (travelog) {
      const comments = travelog.comments.filter(
        (comment: IComment) => comment.id !== commentId
      )
      travelog.comments = comments
    }

    const profileTravelogs = get().profileTravelogs.map(
      (travelog: ITravelog) => {
        if (travelog.id === travelogId) {
          const comments = travelog.comments.filter(
            (comment: IComment) => comment.id !== commentId
          )
          return {
            ...travelog,
            comments,
          }
        }
        return travelog
      }
    )

    set({ travelog, profileTravelogs })
  },
  cleanTravelog: () => {
    set({ travelog: null })
  },
})

import { connectSearchBox } from 'react-instantsearch-dom'
import styled from '@emotion/styled'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'

const StyledAutocomplete = styled(Autocomplete)`
  position: relative;
  border-radius: 4px;
  background-color: #2b2841;
  margin-left: 24px;
  margin-right: 24px;

  & ::placeholder {
    font-size: 14px;
  }

  & #multiple-limit-tags-label {
    color: #a79b9b;
    transform: translate(15px, 11px) scale(1);
  }
  & #multiple-limit-tags-label[data-shrink='true'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  & #multiple-limit-tags {
    color: #a79b9b;
    padding: 9px 4px 9px 6px;
    border: none;
  }
  & .MuiOutlinedInput-root {
    padding: 3px;
    padding-right: 10px !important;
    border-radius: 4px;
  }
  & .MuiAutocomplete-tag {
    color: #a79b9b;
  }
  .MuiAutocomplete-endAdornment {
    top: initial;
  }
`

interface IProps {
  refine: (...args: any[]) => any
  hits: string[]
}

function SearchBox({ refine, hits }: IProps) {
  return (
    <>
      <StyledAutocomplete
        disablePortal
        popupIcon={<SearchIcon sx={{ color: '#a79b9b !important' }} />}
        options={hits}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for a place, interest or activity"
            sx={{ m: 0 }}
            onChange={(e) => refine(e.currentTarget.value)}
          />
        )}
      />
    </>
  )
}

export default connectSearchBox(SearchBox)

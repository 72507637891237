import EntityTypeEnum from '../constants/entityType.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import client from '../gql/clients/apollo-client'
import { NEW_TRIP_PLAN } from '../gql/mutations/tripPlan'
import { GET_TRIP_PLAN_BY_PK } from '../gql/querys/tripPlan'
import { ITripCard, ITripPlan } from '../types'
import { getMentions, getTags, isValidUuid } from '../utils'
import { getImageUrl } from '../utils/useS3'

const getTripPlanPhotoUrl = async (
  id: string,
  photoName: string
): Promise<string> => {
  if (photoName) return await getImageUrl(`${id}/${photoName}`)
}

const getTripPlanCoverImage = async (tripPlan: ITripPlan): Promise<string> => {
  const tripCards = tripPlan?.tripCards

  const travelContents = tripCards
    ?.map((tc: ITripCard) => tc?.tripCardContents)
    .flat()

  let photoUrl = null
  for (const travelContent of travelContents) {
    if (
      travelContent?.entityType?.name === EntityTypeEnum.IMAGE &&
      travelContent.path
    ) {
      photoUrl = await getTripPlanPhotoUrl(
        travelContent?.id,
        travelContent?.path
      )
      break
    }
  }
  return photoUrl
}

const completeTripPlanInfo = async (
  tripPlan /*, userId*/
): Promise<ITripPlan> => {
  /*const tripCards = await Promise.all(
    tripPlan?.tripCards?.map(async (tc: ITripCard) => {
      return {
        ...tc,
        tripCardContents: await tripCardContentService.completeTripCardContents(
          tc?.tripCardContents,
          userId
        ),
      }
    })
  )*/

  const hasTravelog = tripPlan?.travelogs?.length ? true : false
  let travatarInfo
  let profileInfo
  let coverPhoto
  if (tripPlan?.travatarId) {
    travatarInfo = {
      id: tripPlan?.travatarId,
      name: tripPlan?.travatar.name,
    }

    profileInfo = {
      id: tripPlan?.travatar?.user?.id,
      name: tripPlan?.travatar?.user?.name,
    }
  }
  if (tripPlan.tripCards.length) {
    coverPhoto = await getTripPlanCoverImage(tripPlan)
  }

  return {
    ...tripPlan,
    //tripCards,
    coverPhoto,
    hasTravelog,
    profileInfo,
    travatarInfo,
  }
}

export default {
  completeTripPlansInfo: async (
    tripPlans: ITripPlan[]
  ): Promise<ITripPlan[]> => {
    return Promise.all(
      tripPlans.map(async (tripPlan) => completeTripPlanInfo(tripPlan))
    )
  },
  createTripPlan: async (travatarId, title, description) => {
    const metadata = {
      description: description,
      tags: getTags(description),
      mentions: getMentions(description),
    }
    const result = await client.mutate({
      mutation: NEW_TRIP_PLAN,
      variables: {
        travatarId,
        title,
        description,
        metadata,
      },
    })
    const tripPlan = completeTripPlanInfo(result.data.insert_trip_plan_one)
    return tripPlan
  },
  editTripPlan: async (tripPlan: ITripPlan) => {
    const { id, title, description, travatarId, metadata, userId } = tripPlan
    if (!isValidUuid(id)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    const metadataWithhTagsAndMentions = {
      ...metadata,
      tags: getTags(description || ''),
      mentions: getMentions(description || ''),
    }

    const result = await client.mutate({
      mutation: NEW_TRIP_PLAN,
      variables: {
        id,
        title,
        travatarId,
        userId,
        description,
        metadata: metadataWithhTagsAndMentions,
      },
    })

    const tripPlanComplete = completeTripPlanInfo(
      result?.data.insert_trip_plan_one
    )

    return tripPlanComplete
  },
  getTripPlan: async (tripPlanId: string) => {
    if (!isValidUuid(tripPlanId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    const result = await client.query({
      query: GET_TRIP_PLAN_BY_PK,
      variables: {
        tripPlanId,
      },
    })

    const tripPlan = await completeTripPlanInfo(result.data.trip_plan_by_pk)

    return tripPlan
  },
  getOrAssignNewTripPlan: async (tripPlanId: string) => {
    if (!isValidUuid(tripPlanId)) {
      throw new InvalidUuidError('Invalid Trip Plan id')
    }

    let tripPlan: ITripPlan
    const result = await client.query({
      query: GET_TRIP_PLAN_BY_PK,
      variables: {
        tripPlanId,
      },
    })

    if (result.data.trip_plan_by_pk) {
      tripPlan = await completeTripPlanInfo(result.data.trip_plan_by_pk)
    } else {
      tripPlan = { id: tripPlanId }
    }

    return tripPlan
  },
}

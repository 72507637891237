import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import NavBarTop from './NavBarTop'

export default function ExplorerLayout({ children }) {
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          padding: '0 !important',
        }}
      >
        <NavBarTop />
        <Box
          sx={{
            height: '100%',
            padding: ['25px 20px', '25px 40px', '25px 96px'],
          }}
        >
          {children}
        </Box>
      </Container>
    </>
  )
}

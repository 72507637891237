import { gql } from '@apollo/client'
import { TRIP_CARD_CONTENT_FRAGMENT } from './tripCardContent'
import { TRIP_CARD_FRAGMENT } from './tripCard'
import { TRAVELOG_FRAGMENT } from './travelog'

const TRIP_PLAN_FRAGMENT = gql`
  fragment TripPlan on trip_plan {
    id
    metadata
    title
    description
    travatarId: travatar_id
    travatar {
      name
      id
      user {
        id
        name
      }
    }
    tripCards: trip_cards {
      ...TripCard
    }
    travelogs {
      ...Travelog
    }
  }
  ${TRIP_CARD_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
`
const TRIP_PLAN_FRAGMENT_WITH_PRIVATE_MEDIA = gql`
  fragment TripPlanWithPrivateMedia on trip_plan {
    id
    metadata
    title
    travatarId: travatar_id
    travatar {
      id
      name
      user {
        id
        name
      }
    }
    tripCards: trip_cards {
      id
      metadata
      tripPlanId: trip_plan_id
      tripCardType: trip_card_type {
        id
        name
      }
      tripCardContents: trip_card_contents {
        ...TripCardContent
      }
    }
  }
  ${TRIP_CARD_CONTENT_FRAGMENT}
`

export { TRIP_PLAN_FRAGMENT, TRIP_PLAN_FRAGMENT_WITH_PRIVATE_MEDIA }

import { gql } from '@apollo/client'

const COMMENT_FRAGMENT = gql`
  fragment Comments on comment {
    id
    comment
    path
    user {
      name
      profilePhoto: profile_photo
      id
    }
    entityType: entity_type {
      name
      id
    }
  }
`

export { COMMENT_FRAGMENT }

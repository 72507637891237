import { CoordinateType } from '../components/common/SkipdMap/skipMapTypes'

export default function distanceBetweenPoints(
  point1: CoordinateType,
  point2: CoordinateType
): number {
  let { latitude: lat1, longitude: lon1 } = point1
  let { latitude: lat2, longitude: lon2 } = point2

  lon1 = (lon1 * Math.PI) / 180
  lon2 = (lon2 * Math.PI) / 180
  lat1 = (lat1 * Math.PI) / 180
  lat2 = (lat2 * Math.PI) / 180

  const dlon = lon2 - lon1
  const dlat = lat2 - lat1
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2)

  const c = 2 * Math.asin(Math.sqrt(a))

  const r = 6371

  return parseFloat((c * r).toFixed(1))
}

export function pointInRadius(
  point1: CoordinateType,
  point2: CoordinateType,
  radiusKm: number
): boolean {
  const distance = distanceBetweenPoints(point1, point2)

  return distance <= radiusKm
}

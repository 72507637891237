import { formatPointToCoordinateType, similarityPercentageStrings } from '.'
import { ITravelElement } from '../types'
import { pointInRadius } from './distanceBetweenPoints'

export function checkIfTravelElementExists(
  globePoints: ITravelElement[],
  travelElement: ITravelElement
): void {
  globePoints.forEach((te) => {
    const isInRadius = pointInRadius(
      formatPointToCoordinateType(te.location),
      formatPointToCoordinateType(travelElement.location),
      0.05
    )
    if (isInRadius) {
      const similarity = similarityPercentageStrings(
        travelElement.name,
        te.name
      )
      if (similarity > 0.6) {
        throw new Error('This travel element already exists')
      }
    }
  })
}

import { gql } from '@apollo/client'
import { FOLLOWS_FRAGMENT } from '../fragments/follows'
import {
  TRAVATAR_FRAGMENT,
  TRAVATAR_INFO_FRAGMENT,
} from '../fragments/travatar'

const GET_TRAVATAR_BY_ID = gql`
  query getTravatarById($id: uuid!, $path: ltree!) {
    travatar_by_pk(id: $id) {
      ...Travatar
    }
    travatarLikes: like(where: { path: { _eq: $path } }) {
      id
      user_id
    }
    travatarFollowers: follow(where: { path: { _eq: $path } }) {
      ...Follows
    }
  }
  ${FOLLOWS_FRAGMENT}
  ${TRAVATAR_FRAGMENT}
`
const MY_USER_TRAVATAR = gql`
  query getMyUserTravatar($id: uuid!, $path: ltree, $userId: uuid) {
    travatar_by_pk(id: $id) {
      name
      description
      id
      profile_photo
      entity_type_id
      travelogs {
        id
        name
        description
        cover_photo
        travel_content {
          id
          location
          metadata
          path
          entity_type_id
          trip_card_id
          entity_type {
            name
          }
        }
      }
      entity_type {
        id
        name
        follows_aggregate(where: { path: { _eq: $path } }) {
          aggregate {
            count
          }
        }
      }
      user {
        id
      }
    }
    follow_aggregate(
      where: { user_id: { _eq: $userId }, path: { _eq: $path } }
    ) {
      aggregate {
        count
      }
    }
  }
`
const GET_TRAVATARS_BY_USER_ID = gql`
  query GetTravatarsByUserId($id: uuid!) {
    travatar(where: { user_id: { _eq: $id } }) {
      ...Travatar
    }
  }
  ${TRAVATAR_FRAGMENT}
`

const GET_TRAVATARS = gql`
  query GetTravatars($ids: [uuid!]) {
    travatar(where: { id: { _in: $ids } }) {
      ...TravatarInfo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`
export {
  GET_TRAVATARS_BY_USER_ID,
  GET_TRAVATAR_BY_ID,
  MY_USER_TRAVATAR,
  GET_TRAVATARS,
}

import { gql } from '@apollo/client'
import { TRAVATAR_FRAGMENT } from './travatar'
import { LIKES_FRAGMENT } from './like'
import { TRAVEL_ELEMENT_FRAGMENT } from './travelElement'

const PROFILE_INFO_FRAGMENT = gql`
  fragment ProfileInfo on user {
    id
    name
    profilePhoto: profile_photo
    description
    email
  }
`

const USER_FRAGMENT = gql`
  fragment User on user {
    ...ProfileInfo
    dateBirth: date_birth
    legalName: legal_name
    mobileNumber: mobile_number
    passport
    travatars {
      ...Travatar
    }
    travelElementLikes: likes(
      where: { entity_type: { name: { _eq: "TRAVEL_ELEMENT" } } }
    ) {
      ...Likes
    }
    travelogLikes: likes(
      where: { entity_type: { name: { _eq: "TRAVELOG" } } }
    ) {
      ...Likes
    }
    videoLikes: likes(where: { entity_type: { name: { _eq: "VIDEO" } } }) {
      ...Likes
    }
    imageLikes: likes(where: { entity_type: { name: { _eq: "IMAGE" } } }) {
      ...Likes
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${TRAVATAR_FRAGMENT}
  ${LIKES_FRAGMENT}
  ${PROFILE_INFO_FRAGMENT}
`

export { USER_FRAGMENT, PROFILE_INFO_FRAGMENT }

import client from '../gql/clients/apollo-client'
import removeDashes from '../utils/removeDashes'
import EntityTypeEnum from '../constants/entityType.constants'
import { getImageUrl, uploadFile } from '../utils/useS3'
import { UPDATE_USER } from '../gql/mutations/profile'
import { getProfilePhotoUrl } from '../utils/profile'
import {
  GET_USER,
  GET_FOLLOWERS_USERS,
  GET_FOLLOWING_USERS,
} from '../gql/querys/profile'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import { IProfile, IProfileInfo } from '../types'

export default {
  getProfile: async (id: string) => {
    if (!isValidUuid(id)) {
      throw new InvalidUuidError('Invalid profile id')
    }
    const result = await client.query({
      query: GET_USER,
      variables: {
        id,
        path: `${EntityTypeEnum.USER}.${removeDashes(id)}`,
      },
    })

    const profilePhoto = result?.data?.user_by_pk.profilePhoto

    let imageUrl = null

    if (profilePhoto) {
      imageUrl = await getImageUrl(
        `${
          profilePhoto?.includes('default') ? 'travatar_default_picture' : id
        }/${profilePhoto}`
      )
    }

    const profile = {
      ...result?.data?.user_by_pk,
      profilePhotoUrl: imageUrl,
    }

    const followers = result?.data?.followers.map((f) => f.userId)

    const profileFollowing = result?.data?.profilesFollowing.map((f) =>
      f?.path?.split('.').pop()
    )

    const travatarFollowing = result?.data?.travatarsFollowing.map((f) =>
      f?.path?.split('.').pop()
    )

    const travelogLikes = profile.travelogLikes

    return {
      profile,
      followers,
      profileFollowing,
      travatarFollowing,
      travelogLikes,
    }
  },
  editProfile: async (newProfileInfo, profilePhoto): Promise<IProfile> => {
    if (!isValidUuid(newProfileInfo.profile.id)) {
      throw new InvalidUuidError('Invalid profile id')
    }

    const result = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        id: newProfileInfo.profile.id,
        description: newProfileInfo.description,
        name: newProfileInfo.name,
        profilePhoto: profilePhoto
          ? profilePhoto?.name
          : newProfileInfo.profile.profilePhoto,
      },
    })

    if (profilePhoto) {
      await uploadFile({
        rawKey: `${newProfileInfo.profile.id}/${profilePhoto.name}`,
        file: profilePhoto,
        contentType: profilePhoto.type,
      })
    }

    const profilePhotoUrl = await getProfilePhotoUrl(
      result?.data.update_user.returning[0].profilePhoto,
      newProfileInfo.profile.id
    )
    const profile = {
      ...result?.data.update_user.returning[0],
      profilePhotoUrl,
    } as IProfile

    return profile
  },
  getFollowersProfiles: async (ids: string[]): Promise<IProfileInfo[]> => {
    const result = await client.query({
      query: GET_FOLLOWERS_USERS,
      variables: { ids },
    })

    const profiles = await Promise.all(
      result.data.user.map(async (profile) => {
        const profilePhotoName = profile.profilePhoto
        const profilePhotoUrl = await getImageUrl(
          `${
            profilePhotoName?.includes('default')
              ? 'travatar_default_picture'
              : profile.id
          }/${profilePhotoName}`
        )
        return {
          ...profile,
          profilePhotoUrl,
        }
      })
    )

    return profiles
  },
  getFollowingProfiles: async (ids: string[]): Promise<IProfileInfo[]> => {
    const result = await client.query({
      query: GET_FOLLOWING_USERS,
      variables: { ids },
    })

    const profiles = Promise.all(
      result.data.user.map(async (profile) => {
        const profilePhotoName = profile.profilePhoto
        const profilePhotoUrl = await getImageUrl(
          `${
            profilePhotoName?.includes('default')
              ? 'travatar_default_picture'
              : profile.id
          }/${profilePhotoName}`
        )
        return {
          ...profile,
          profilePhotoUrl,
        }
      })
    )

    return profiles
  },
}

import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_FRAGMENT } from './travelElement'
import { TRAVELOG_FRAGMENT } from './travelog'
import { TRIP_PLAN_FRAGMENT } from './tripPlan'

const TRAVATAR_INFO_FRAGMENT = gql`
  fragment TravatarInfo on travatar {
    id
    name
    description
    profilePhoto: profile_photo
  }
`

const TRAVATAR_FRAGMENT = gql`
  fragment Travatar on travatar {
    ...TravatarInfo
    profileInfo: user {
      id
      name
      profilePhoto: profile_photo
    }
    tripPlans: trip_plans(order_by: { created_at: desc }) {
      ...TripPlan
    }
    travelElements: travel_elements {
      ...TravelElement
    }
  }
  ${TRAVELOG_FRAGMENT}
  ${TRIP_PLAN_FRAGMENT}
  ${TRAVATAR_INFO_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
`

export { TRAVATAR_FRAGMENT, TRAVATAR_INFO_FRAGMENT }

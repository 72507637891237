import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from '../fragments/comment'

const GET_COMMENTS_BY_PATH = gql`
  query getComments($paths: [ltree]!) {
    comment(where: { path: { _in: $paths } }) {
      ...Comments
    }
  }
  ${COMMENT_FRAGMENT}
`
export { GET_COMMENTS_BY_PATH }

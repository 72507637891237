import EntityTypeEnum from '../constants/entityType.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import client from '../gql/clients/apollo-client'
import {
  INSERT_TRAVEL_ELEMENT,
  UPDATE_TRAVEL_ELEMENT,
} from '../gql/mutations/travelElement'
import { GET_LIKES_BY_PATH } from '../gql/querys/likes'
import {
  GET_TRAVEL_ELEMENT,
  GET_TRAVEL_ELEMENTS,
  GET_TRAVEL_ELEMENTS_BY_ID,
} from '../gql/querys/travelElement'
import {
  ITravelElement,
  IFileWithLocation,
  ITravelElementContent,
  IFollow,
  ITravelElementRelatedTravelogs,
  IComment,
  ITravelog,
} from '../types'
import { isValidUuid } from '../utils'
import removeDashes from '../utils/removeDashes'
import { getTripCardContentUrl } from '../utils/travelContent'
import { getImageUrl, uploadFile } from '../utils/useS3'
import IdsEnum from '../constants/ids.constants'
import { v4 as uuidv4 } from 'uuid'
import { INSERT_TRAVEL_ELEMENT_CONTENTS } from '../gql/mutations/travelElementContent'
import { checkIfTravelElementExists } from '../utils/travelElement'
import { GET_TRAVEL_ELEMENT_RELATED_TRAVELOGS } from '../gql/querys/travelog'
import travelogService from './travelogService'
import { GET_COMMENTS_BY_PATH } from '../gql/querys/comments'
import { GET_FOLLOWS_BY_PATH } from '../gql/querys/follows'

type CompleteTravelElementInfoProps = {
  travelElement: ITravelElement
  travelElementLikes?: string[]
  travelElementFollowers?: string[]
  travelElementComments?: IComment[]
}

const completeTravelElementInfo = async ({
  travelElement,
  travelElementLikes,
  travelElementFollowers,
  travelElementComments,
}: CompleteTravelElementInfoProps): Promise<ITravelElement> => {
  const travelElementPath = `${EntityTypeEnum.TRAVEL_ELEMENT}.${removeDashes(
    travelElement?.id
  )}`

  if (!travelElementLikes?.length) {
    const resultLikes = await client.query({
      query: GET_LIKES_BY_PATH,
      variables: {
        path: travelElementPath,
      },
    })
    travelElementLikes = resultLikes?.data?.like?.map((like) => like.userId)
  }

  if (!travelElementComments?.length) {
    const resultComments = await client.query({
      query: GET_COMMENTS_BY_PATH,
      variables: { paths: travelElementPath },
    })
    travelElementComments = resultComments?.data?.comment
  }

  if (!travelElementFollowers?.length) {
    const resultFollowers = await client.query({
      query: GET_FOLLOWS_BY_PATH,
      variables: { path: travelElementPath },
    })
    travelElementFollowers = resultFollowers?.data?.follow?.map(
      (follow) => follow.userId
    )
  }

  const coverPhotoUrl = await getImageUrl(
    `${travelElement?.id}/${travelElement?.coverPhoto}`
  )

  const user = travelElement?.travatar?.profileInfo
  const profileInfo = {
    id: user?.id,
    name: user?.name,
  }
  return {
    ...travelElement,
    coverPhotoUrl,
    likes: travelElementLikes,
    comments: travelElementComments,
    followers: travelElementFollowers,
    profileInfo,
  }
}

export default {
  getTravelElement: async (
    travelElementId: string
  ): Promise<ITravelElement> => {
    if (!isValidUuid(travelElementId)) {
      throw new InvalidUuidError('Invalid travel element id')
    }

    const result = await client.query({
      query: GET_TRAVEL_ELEMENT,
      variables: {
        id: travelElementId,
        path: `${EntityTypeEnum.TRAVEL_ELEMENT}.${removeDashes(
          travelElementId
        )}`,
      },
    })
    const travelElement: ITravelElement = result.data.travel_element_by_pk
    const travelElementLikes: string[] = result.data.travelElementLikes?.map(
      (like) => like.userId
    )
    const travelElementFollowers: string[] =
      result.data.travelElementFollowers.map(
        (follows: IFollow) => follows.userId
      )

    const travelElementComments: IComment[] = result.data.travelElementComments

    const travelElementComplete = await completeTravelElementInfo({
      travelElement,
      travelElementLikes,
      travelElementFollowers,
      travelElementComments,
    })

    return travelElementComplete
  },
  newTravelElement: async (
    newTravelElementData,
    travelElementsInGlobe: ITravelElement[]
  ): Promise<ITravelElement> => {
    checkIfTravelElementExists(travelElementsInGlobe, newTravelElementData)

    const imageDate = Date.now()
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT,
      variables: {
        cover_photo: newTravelElementData.heroImage
          ? `travel_element_hero${imageDate}.jpg`
          : null,
        entityTypeId: IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT,
        description: newTravelElementData.description,
        travatarId: newTravelElementData.travatarId,
        userId: newTravelElementData.userId,
        name: newTravelElementData.name,
        metadata: newTravelElementData.metadata,
        location: newTravelElementData.location,
      },
    })

    const newTravelElement = result.data.insert_travel_element_one

    if (newTravelElementData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelElement.id}/travel_element_hero${imageDate}.jpg`,
        contentType: newTravelElementData.heroImage.type,
        file: newTravelElementData.heroImage,
      })
    }

    const travelElement = completeTravelElementInfo({
      travelElement: newTravelElement,
    })

    return travelElement
  },

  editTravelElement: async (newTravelElementData): Promise<ITravelElement> => {
    if (!isValidUuid(newTravelElementData.id)) {
      throw new InvalidUuidError('Invalid travel element id')
    }
    const coverPhoto = `${
      newTravelElementData.id
    }/travel_element_hero${Date.now()}.jpg`
    const result = await client.mutate({
      mutation: UPDATE_TRAVEL_ELEMENT,
      variables: {
        id: newTravelElementData.id,
        description: newTravelElementData.description,
        location: newTravelElementData.location,
        metadata: newTravelElementData.metadata,
        name: newTravelElementData.name,
        coverPhoto: newTravelElementData.heroImage
          ? coverPhoto
          : newTravelElementData.coverPhoto,
      },
    })

    if (newTravelElementData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelElementData.id}/${coverPhoto}`,
        contentType: newTravelElementData.heroImage.contentType,
        file: newTravelElementData.heroImage,
      })
    }

    const travelElement = completeTravelElementInfo({
      travelElement: result.data.update_travel_element_by_pk,
    })
    return travelElement
  },
  getTravelElements: async (travelElementIds: string[]) => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS_BY_ID,
      variables: {
        travelElementIds,
      },
    })

    let travelElements = result?.data.travel_element

    if (travelElements.length) {
      travelElements = await Promise.all(
        travelElements.map(
          async (te) => await completeTravelElementInfo({ travelElement: te })
        )
      )
    }

    return travelElements
  },
  completeTravelElementsInfo: async (
    travelElements: ITravelElement[]
  ): Promise<ITravelElement[]> =>
    await Promise.all(
      travelElements?.map(async (te) =>
        completeTravelElementInfo({ travelElement: te })
      )
    ),
  getGlobePoints: async () => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENTS,
    })

    return result.data.travel_element
  },
  addTravelElementContentWithUrl: async (
    travelElementId: string,
    travelElementContents
  ) => {
    const travelElementContentIdAssign = travelElementContents?.map((tec) => {
      return { ...tec, id: uuidv4() }
    })
    const travelElementContentInsertInput = travelElementContentIdAssign?.map(
      (tec) => {
        return {
          id: tec.id,
          location: tec.location,
          metadata: { description: tec.description },
          path: tec.file.path,
          entity_type_id: tec.file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
          travel_element_id: travelElementId,
        }
      }
    )

    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementContentInsertInput },
    })

    const newTravelElementContents =
      result?.data?.insert_travel_element_content.returning

    await Promise.all(
      travelElementContentIdAssign.map(async (tec) => {
        await uploadFile({
          rawKey: `${tec.id}/${tec.file.name}`,
          contentType: tec.file.type,
          file: tec.file,
        })
      })
    )

    const newTravelElementContentsWithUrl = await Promise.all(
      newTravelElementContents.map(async (tec: ITravelElementContent) => {
        const travelElementContentUrl = await getTripCardContentUrl(
          tec.id,
          tec.path
        )
        return {
          ...tec,
          travelElementContentUrl,
        }
      })
    )

    return newTravelElementContentsWithUrl
  },
  insertContents: async (
    filesWithLocation: IFileWithLocation[],
    travelElementId: string
  ): Promise<ITravelElementContent[]> => {
    const travelElementInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          travelElementId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
        }
      }
    )

    const result = await client.mutate({
      mutation: INSERT_TRAVEL_ELEMENT_CONTENTS,
      variables: { travelElementContents: travelElementInsertInput },
    })

    const newTravelElementContents =
      result?.data?.insert_travel_element_content?.returning

    const newTravelElementContentsWithUrl = await Promise.all(
      newTravelElementContents.map(async (tec, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tec.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)
        return {
          ...tec,
          travelElementContentUrl: url,
        }
      })
    )

    return newTravelElementContentsWithUrl
  },
  getTravelElementRelatedTravelogs: async (
    travelElementId
  ): Promise<ITravelElementRelatedTravelogs> => {
    const result = await client.query({
      query: GET_TRAVEL_ELEMENT_RELATED_TRAVELOGS,
      variables: {
        travelElementId,
      },
    })
    const travelogs: ITravelog[] = result.data.travelog

    const travelogsWithUrl = await travelogService.completeTravelogsInfo({
      travelogs,
    })

    const travelogsRelated = {
      travelogs: travelogsWithUrl,
      travelElementId: travelElementId,
    }

    return travelogsRelated
  },
}

import client from '../gql/clients/apollo-client'
import {
  INSERT_TRIP_CARD,
  REMOVE_TRIP_CARDS,
  UPDATE_TRIP_CARD,
} from '../gql/mutations/tripCard'
import { ITripCard } from '../types'
import { formatTripCardItineraryMetadata } from '../utils/tripPlan'

export default {
  insertTripCards: async (itinerary, tripPlanId) => {
    const cardsMetadata = formatTripCardItineraryMetadata(itinerary)

    const tripCards = await Promise.all(
      cardsMetadata.map(async (metadata) => {
        const travelElementId = metadata?.travelElementId
        const result = await client.mutate({
          mutation: INSERT_TRIP_CARD,
          variables: { tripPlanId, travelElementId, metadata },
        })
        const tripCard = result.data.insert_trip_card_one
        return tripCard
      })
    )
    return tripCards
  },
  insertNewTripCards: async (itinerary, tripPlanId): Promise<ITripCard[]> => {
    const newCards = Object.assign({}, itinerary)

    for (const day in newCards) {
      newCards[day] = newCards[day].filter((card) => !card.id)
    }
    const cardsMetadata = formatTripCardItineraryMetadata(newCards)

    const newTripCards = await Promise.all(
      cardsMetadata.map(async (metadata) => {
        const travelElementId = metadata?.travelElementId
        const result = await client.mutate({
          mutation: INSERT_TRIP_CARD,
          variables: {
            tripPlanId,
            travelElementId,
            metadata,
          },
        })
        const card = result.data.insert_trip_card_one
        return card
      })
    )
    return newTripCards
  },
  removeTripCards: async (tripCardIds): Promise<void> => {
    await client.mutate({
      mutation: REMOVE_TRIP_CARDS,
      variables: { tripCardIds },
    })
  },
  editTripCards: async (itinerary) => {
    const cards = Object.values(itinerary).flat()
    const metadata = formatTripCardItineraryMetadata(itinerary)
    const tripCards = await Promise.all(
      cards.map(async (card: ITripCard, index) => {
        if (card.id) {
          const result = await client.mutate({
            mutation: UPDATE_TRIP_CARD,
            variables: { id: card.id, metadata: metadata[index] },
          })
          const tripCard = result.data.update_trip_card_by_pk
          return tripCard
        }
      })
    )
    return tripCards
  },
}

import { gql } from '@apollo/client'
import { LIKES_FRAGMENT } from '../fragments/like'

const UNLIKE = gql`
  mutation deleteLike($path: ltree!, $userId: uuid!) {
    delete_like(where: { path: { _eq: $path }, user_id: { _eq: $userId } }) {
      affected_rows
    }
  }
`
const INSERT_LIKE = gql`
  mutation insertLike($path: ltree!, $entityTypeId: uuid!, $userId: uuid!) {
    insert_like_one(
      object: { path: $path, entity_type_id: $entityTypeId, user_id: $userId }
    ) {
      ...Likes
    }
  }
  ${LIKES_FRAGMENT}
`
export { UNLIKE, INSERT_LIKE }

enum EntityTypeEnum {
  TRAVELOG = 'TRAVELOG',
  TRAVATAR = 'TRAVATAR',
  TRAVEL_ELEMENT = 'TRAVEL_ELEMENT',
  TRAVEL_ELEMENT_CONTENT = 'TRAVEL_ELEMENT_CONTENT',
  TRIP_CARD_CONTENT = 'TRIP_CARD_CONTENT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  GIF = 'GIF',
  BLOG_POST = 'BLOG_POST',
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  USER = 'USER',
}

export default EntityTypeEnum

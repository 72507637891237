enum IdsEnum {
  ENTITY_TYPE_TRAVELOG = 'c6339ac7-adc4-4194-9767-f3eb88dd8c0a',
  ENTITY_TYPE_TRAVATAR = 'c6d47cc5-862c-46d3-9a43-57aab618dad5',
  ENTITY_TYPE_TRAVEL_CONTENT = '7bcf53ae-a61b-4f29-b925-641d85d46846',
  ENTITY_TYPE_PROFILE = '47355e81-6611-4f28-ac88-5f46f5e3b890',
  ENTITY_TYPE_IMAGE = 'd6737de7-b811-47e0-af7d-8ddce136ad30',
  ENTITY_TYPE_VIDEO = 'b4f77ec6-19a1-4e1a-b801-3e1255434029',
  ENTITY_TYPE_TRAVEL_ELEMENT = '130a0e4b-4c38-4c1d-83e2-52e597cf1378',
  ENTITY_TYPE_TRAVEL_ELEMENT_CONTENT = '85263346-2d03-4ff8-bebf-7ef51275f38c',
  ENTITY_TYPE_TRIP_CARD_CONTENT = '7876aecf-3852-4fa0-9a13-255d5607acbe',
}
export default IdsEnum

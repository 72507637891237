import { gql } from '@apollo/client'
import {
  TRAVATAR_INFO_FRAGMENT,
  TRAVATAR_FRAGMENT,
} from '../fragments/travatar'

const UPDATE_TRAVATAR = gql`
  mutation updateTravatar(
    $id: uuid!
    $description: String
    $name: String!
    $profilePhoto: String
  ) {
    update_travatar_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        profile_photo: $profilePhoto
      }
    ) {
      ...Travatar
    }
  }
  ${TRAVATAR_FRAGMENT}
`
const INSERT_TRAVATAR = gql`
  mutation InsertTravatar(
    $description: String
    $name: String!
    $userId: uuid!
    $profilePhoto: String
  ) {
    insert_travatar_one(
      object: {
        name: $name
        profile_photo: $profilePhoto
        description: $description
        user_id: $userId
      }
    ) {
      ...TravatarInfo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`

const DELETE_TRAVATAR_BY_ID = gql`
  mutation DeleteTravatarById($id: uuid!) {
    delete_travatar_by_pk(id: $id) {
      ...TravatarInfo
    }
  }
  ${TRAVATAR_INFO_FRAGMENT}
`
export { UPDATE_TRAVATAR, INSERT_TRAVATAR, DELETE_TRAVATAR_BY_ID }

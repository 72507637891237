import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from '../fragments/comment'
import { FOLLOWS_FRAGMENT } from '../fragments/follows'
import { TRAVEL_ELEMENT_FRAGMENT } from '../fragments/travelElement'

const GET_TRAVEL_ELEMENT = gql`
  query GetTravelElement($id: uuid!, $path: ltree!) {
    travel_element_by_pk(id: $id) {
      ...TravelElement
    }
    travelElementLikes: like(where: { path: { _eq: $path } }) {
      id
      userId: user_id
    }
    travelElementFollowers: follow(where: { path: { _eq: $path } }) {
      ...Follows
    }
    travelElementComments: comment(where: { path: { _eq: $path } }) {
      ...Comments
    }
  }
  ${FOLLOWS_FRAGMENT}
  ${TRAVEL_ELEMENT_FRAGMENT}
  ${COMMENT_FRAGMENT}
`

const GET_TRAVEL_ELEMENTS = gql`
  query GetTravelElements {
    travel_element(where: { location: { _is_null: false } }) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`
const GET_TRAVEL_ELEMENTS_BY_ID = gql`
  query GetTravelElementsById($travelElementIds: [uuid!]) {
    travel_element(where: { id: { _in: $travelElementIds } }) {
      ...TravelElement
    }
  }
  ${TRAVEL_ELEMENT_FRAGMENT}
`
export { GET_TRAVEL_ELEMENT, GET_TRAVEL_ELEMENTS, GET_TRAVEL_ELEMENTS_BY_ID }

import { gql } from '@apollo/client'

const TRIP_CARD_CONTENT_FRAGMENT = gql`
  fragment TripCardContent on trip_card_content {
    id
    entityType: entity_type {
      id
      name
    }
    location
    metadata
    isPrivate: is_private
    likesAmount: computed_likes
    path
    tripCardId: trip_card_id
    tripCard: trip_card {
      metadata
      tripPlan: trip_plan {
        id
      }
    }
  }
`

export { TRIP_CARD_CONTENT_FRAGMENT }

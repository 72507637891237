import { gql } from '@apollo/client'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from '../fragments/travelElementContent'

const REMOVE_TRAVEL_ELEMENT_CONTENTS = gql`
  mutation RemoveTravelElementContents($travelElementContentsIds: [uuid!]) {
    delete_travel_element_content(
      where: { id: { _in: $travelElementContentsIds } }
    ) {
      returning {
        id
      }
    }
  }
`

const INSERT_TRAVEL_ELEMENT_CONTENTS = gql`
  mutation InsertTravelElementContents(
    $travelElementContents: [travel_element_content_insert_input!]!
  ) {
    insert_travel_element_content(objects: $travelElementContents) {
      returning {
        ...TravelElementContent
      }
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
`

export { REMOVE_TRAVEL_ELEMENT_CONTENTS, INSERT_TRAVEL_ELEMENT_CONTENTS }

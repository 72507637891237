import EntityTypeEnum from '../constants/entityType.constants'
import client from '../gql/clients/apollo-client'
import {
  DELETE_TRAVATAR_BY_ID,
  INSERT_TRAVATAR,
  UPDATE_TRAVATAR,
} from '../gql/mutations/travatar'
import removeDashes from '../utils/removeDashes'
import { GET_TRAVATARS, GET_TRAVATAR_BY_ID } from '../gql/querys/travatar'
import { IFollow, ILike, ITravatar } from '../types'
import { getImageUrl, uploadFile } from '../utils/useS3'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import tripPlanService from './tripPlanService'
import travelElementService from './travelElementService'

const getCoverPhotoUrl = async (
  id: string,
  profilePhotoName: string
): Promise<string> => {
  if (profilePhotoName)
    return await getImageUrl(
      `${
        profilePhotoName?.includes('default') ? 'travatar_default_picture' : id
      }/${profilePhotoName}`
    )
}

export default {
  getTravatar: async (id: string): Promise<ITravatar> => {
    if (!isValidUuid(id)) {
      throw new InvalidUuidError('Invalid travatar id')
    }

    const path = `${EntityTypeEnum.TRAVATAR}.${removeDashes(id)}`
    const result = await client.query({
      query: GET_TRAVATAR_BY_ID,
      variables: {
        id,
        path,
      },
    })

    const travatar = result.data.travatar_by_pk as ITravatar
    const travatarLikes = result.data.travatarLikes.map(
      (like: ILike) => like.userId
    ) as string[]
    const travatarFollowers = result.data.travatarFollowers.map(
      (follows: IFollow) => follows.userId
    ) as string[]
    const tripPlans = await tripPlanService.completeTripPlansInfo(
      travatar.tripPlans
    )
    const travelElements =
      await travelElementService.completeTravelElementsInfo(
        travatar.travelElements
      )
    const coverPhotoUrl = await getCoverPhotoUrl(
      travatar.id,
      travatar.profilePhoto
    )
    return {
      ...travatar,
      coverPhotoUrl,
      tripPlans,
      likes: travatarLikes,
      followers: travatarFollowers,
      travelElements,
    }
  },
  newTravatar: async (newPhoto, newTravatarData) => {
    const result = await client.mutate({
      mutation: INSERT_TRAVATAR,
      variables: {
        description: newTravatarData.description,
        name: newTravatarData.name,
        userId: newTravatarData.userId,
        profilePhoto: newPhoto
          ? `travatar_photo.${newPhoto?.name?.split('.').pop()}`
          : null,
      },
    })

    const newTravatarInfo = result.data.insert_travatar_one as ITravatar

    if (newPhoto) {
      await uploadFile({
        rawKey: `${newTravatarInfo.id}/travatar_photo.${newPhoto.name
          .split('.')
          .pop()}`,
        contentType: newPhoto.contentType,
        file: newPhoto,
      })
    }

    const photoName = newPhoto
      ? `travatar_photo.${newPhoto.name.split('.').pop()}`
      : null

    let coverPhotoUrl
    if (photoName) {
      coverPhotoUrl = await getCoverPhotoUrl(newTravatarInfo.id, photoName)
    }

    const travatar = {
      ...newTravatarInfo,
      profile_photo: photoName,
      coverPhotoUrl,
    } as ITravatar

    return travatar
  },
  editTravatar: async (newPhoto, newTravatarData) => {
    if (!isValidUuid(newTravatarData.id)) {
      throw new InvalidUuidError('Invalid travatar id')
    }

    const result = await client.mutate({
      mutation: UPDATE_TRAVATAR,
      variables: {
        id: newTravatarData.id,
        description: newTravatarData.description,
        name: newTravatarData.name,
        profilePhoto: newTravatarData.coverPhoto,
      },
    })

    if (newPhoto) {
      await uploadFile({
        rawKey: `${newTravatarData.id}/travatar_photo.${newPhoto.name
          .split('.')
          .pop()}`,
        contentType: newPhoto.contentType,
        file: newPhoto,
      })
    }

    const photoName = newTravatarData.coverPhoto
    const coverPhotoUrl = await getCoverPhotoUrl(newTravatarData.id, photoName)

    const travatar = {
      ...result.data?.update_travatar_by_pk,
      coverPhotoUrl,
    } as ITravatar

    return travatar
  },
  getTravatars: async (ids: string[]): Promise<ITravatar[]> => {
    const result = await client.query({
      query: GET_TRAVATARS,
      variables: { ids },
    })
    const travatars = (await Promise.all(
      result.data.travatar.map(async (travatar) => {
        const coverPhotoUrl = await getCoverPhotoUrl(
          travatar.id,
          travatar.profile_photo
        )
        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )) as ITravatar[]

    return travatars
  },
  fetchCoverImages: async (travatars: ITravatar[]): Promise<ITravatar[]> => {
    return await Promise.all(
      travatars.map(async (travatar: ITravatar) => {
        const coverPhotoUrl = await getCoverPhotoUrl(
          travatar.id,
          travatar.profilePhoto
        )

        return {
          ...travatar,
          coverPhotoUrl,
        }
      })
    )
  },
  deleteTravatar: async (travatarId) => {
    await client.mutate({
      mutation: DELETE_TRAVATAR_BY_ID,
      variables: { id: travatarId },
    })
  },
}

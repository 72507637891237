import { gql } from '@apollo/client'
import { USER_FRAGMENT } from '../fragments/profile'

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: uuid!
    $name: String!
    $description: String
    $profilePhoto: String
  ) {
    update_user(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        profile_photo: $profilePhoto
      }
    ) {
      returning {
        ...User
      }
    }
  }
  ${USER_FRAGMENT}
`

const UPDATE_USER_PRIVATE_DETAILS = gql`
  mutation UpdateUserPrivateDetails(
    $id: uuid!
    $date_birth: String
    $legal_name: String
    $mobile_number: String
    $passport: String
  ) {
    update_user(
      where: { id: { _eq: $id } }
      _set: {
        date_birth: $date_birth
        legal_name: $legal_name
        mobile_number: $mobile_number
        passport: $passport
      }
    ) {
      returning {
        ...User
      }
    }
  }
  ${USER_FRAGMENT}
`
export { UPDATE_USER_PRIVATE_DETAILS, UPDATE_USER }

import { ITripCardContent, ITripPlan } from '../types'
import { getImageUrl } from './useS3'

//REVIEW IF WE NEED THIS FUNCTION - Alonzo created getContentUrl.
export const getTripCardContentUrl = async (
  id: string,
  photoName: string
): Promise<string> => {
  if (photoName) return await getImageUrl(`${id}/${photoName}`)
}

export const formatTripCardContentByDays = (
  travelContent: ITripCardContent[]
) => {
  const travelContentByDay = travelContent?.reduce((acc, tc) => {
    ;(acc[tc.tripCard.metadata.itinerary.day] =
      acc[tc.tripCard.metadata.itinerary.day] || []).push(tc)
    return acc
  }, {})
  return travelContentByDay
}

export const insertTripCardContentUrlToTripCards = async (
  tripPlan: ITripPlan
) => {
  return await Promise.all(
    tripPlan.tripCards.map(async (tripCard) => {
      const tripCardContents = await Promise.all(
        tripCard.tripCardContents.map(async (tc) => {
          const travelContentUrl = await getTripCardContentUrl(tc.id, tc.path)
          return {
            ...tc,
            travelContentUrl,
          }
        })
      )
      tripCard = { ...tripCard, tripCardContents }
      return tripCard
    })
  )
}

import { gql } from '@apollo/client'
import { LIKES_FRAGMENT } from './like'
import { TRAVEL_ELEMENT_CONTENT_FRAGMENT } from './travelElementContent'

const TRAVEL_ELEMENT_FRAGMENT = gql`
  fragment TravelElement on travel_element {
    coverPhoto: cover_photo
    description
    name
    id
    isVerified: is_verified
    location
    metadata
    travatarId: travatar_id
    travatar {
      id
      name
      profilePhoto: profile_photo
      profileInfo: user {
        id
        name
        profilePhoto: profile_photo
      }
    }
    travelElementContents: travel_element_contents {
      ...TravelElementContent
    }
  }
  ${TRAVEL_ELEMENT_CONTENT_FRAGMENT}
  ${LIKES_FRAGMENT}
`

export { TRAVEL_ELEMENT_FRAGMENT }

import { getImageUrl, uploadFile } from '../utils/useS3'
import { IComment, ILike, ITravelog } from '../types'
import client from '../gql/clients/apollo-client'
import { GET_TRAVELOGS, GET_TRAVELOG_BY_ID } from '../gql/querys/travelog'
import removeDashes from '../utils/removeDashes'
import EntityTypeEnum from '../constants/entityType.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import { isValidUuid } from '../utils'
import { INSERT_TRAVELOG, UPDATE_TRAVELOG } from '../gql/mutations/travelog'
import IdsEnum from '../constants/ids.constants'
import { GET_LIKES_BY_PATH } from '../gql/querys/likes'
import { GET_COMMENTS_BY_PATH } from '../gql/querys/comments'

type completeTravelogInfoProps = {
  travelog: ITravelog
  travelogLikes?: ILike[]
  travelogComments?: IComment[]
  profilePhotoUrl?: string
}

const completeTravelogInfo = async ({
  travelog,
  travelogLikes,
  travelogComments,
  profilePhotoUrl,
}: completeTravelogInfoProps): Promise<ITravelog> => {
  const coverPhotoUrl = await getImageUrl(
    `${travelog.id}/${travelog.coverPhoto}`
  )
  const path = `${EntityTypeEnum.TRAVELOG}.${removeDashes(travelog.id)}`

  let likes
  if (travelogLikes?.length) {
    likes = travelogLikes?.map((like) => like.userId)
  } else {
    const likesResult = await client.query({
      query: GET_LIKES_BY_PATH,
      variables: { path },
    })
    likes = likesResult?.data?.like?.map((like) => like.userId)
  }

  let comments: IComment[]
  if (travelogComments?.length) {
    comments = travelogComments
  } else {
    const commentsResult = await client.query({
      query: GET_COMMENTS_BY_PATH,
      variables: { paths: path },
    })
    comments = commentsResult?.data?.comment
  }

  const tripPlanId = travelog?.tripPlanId
  const travatar = travelog?.tripPlan?.travatar
  const profile = travelog?.tripPlan?.travatar?.user
  const photoUrl =
    profilePhotoUrl ||
    (await getImageUrl(
      `${travelog.tripPlan.travatar.user.id}/${travelog.tripPlan.travatar.user.profilePhoto}`
    ))
  return {
    ...travelog,
    coverPhotoUrl,
    likes,
    comments,
    tripPlanId,
    travatarInfo: {
      id: travatar?.id,
      name: travatar?.name,
    },
    profileInfo: {
      id: profile?.id,
      name: profile?.name,
      photoUrl,
    },
  }
}

type CompleteTravelogsInfoProps = {
  travelogs: ITravelog[]
  profilePhotoUrl?: string
}

export default {
  completeTravelogsInfo: async ({
    travelogs,
    profilePhotoUrl,
  }: CompleteTravelogsInfoProps): Promise<ITravelog[]> => {
    return await Promise.all(
      travelogs.map(async (travelog) =>
        completeTravelogInfo({ travelog, profilePhotoUrl })
      )
    )
  },

  getTravelogs: async (travelogIds: string[]) => {
    const result = await client.query({
      query: GET_TRAVELOGS,
      variables: {
        travelogIds,
      },
    })
    const travelogsResult = result?.data?.travelog

    const travelogs = (await Promise.all(
      travelogsResult.map(
        async (travelog) => await completeTravelogInfo({ travelog })
      )
    )) as ITravelog[]

    return travelogs
  },
  getTravelog: async (travelogId: string): Promise<ITravelog> => {
    if (!isValidUuid(travelogId)) {
      throw new InvalidUuidError('Invalid travelog id')
    }

    const result = await client.query({
      query: GET_TRAVELOG_BY_ID,
      variables: {
        id: travelogId,
        path: `${EntityTypeEnum.TRAVELOG}.${removeDashes(travelogId)}`,
      },
    })

    const travelogResult = result?.data?.travelog_by_pk
    const travelogLikes = result?.data?.travelogLikes
    const travelogComments = result?.data?.travelogComments

    const travelog = await completeTravelogInfo({
      travelog: travelogResult,
      travelogLikes,
      travelogComments,
    })
    return travelog
  },
  createTravelog: async (travelogData): Promise<ITravelog> => {
    const imageDate = Date.now()
    const result = await client.mutate({
      mutation: INSERT_TRAVELOG,
      variables: {
        coverPhoto: travelogData.heroImage
          ? `travelog_hero${imageDate}.jpg`
          : null,
        name: travelogData.name,
        description: travelogData.description,
        tripPlanId: travelogData.tripPlanId,
        entityTypeId: IdsEnum.ENTITY_TYPE_TRAVELOG,
      },
    })
    const newTravelogData = result.data.insert_travelog_one

    if (travelogData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelogData.id}/travelog_hero${imageDate}.jpg`,
        contentType: travelogData.heroImage.type,
        file: travelogData.heroImage,
      })
    }

    const travelog = await completeTravelogInfo({
      travelog: result.data.insert_travelog_one,
    })
    return travelog
  },
  editTravelog: async (newTravelogData) => {
    const { id, name: newName, description: newDescription } = newTravelogData
    const coverPhoto = `${newTravelogData.id}/travelog_hero${Date.now()}.jpg`
    const result = await client.mutate({
      mutation: UPDATE_TRAVELOG,
      variables: {
        id,
        name: newName,
        description: newDescription,
        coverPhoto: newTravelogData.heroImage
          ? coverPhoto
          : newTravelogData.coverPhoto,
      },
    })
    if (newTravelogData.heroImage) {
      await uploadFile({
        rawKey: `${newTravelogData.id}/${coverPhoto}`,
        contentType: newTravelogData.heroImage.contentType,
        file: newTravelogData.heroImage,
      })
    }

    const travelog = await completeTravelogInfo({
      travelog: result.data.update_travelog_by_pk,
    })

    return travelog
  },
}

import client from '../gql/clients/apollo-client'
import {
  INSERT_TRAVEL_CONTENTS,
  REMOVE_TRAVEL_CONTENTS,
  UPDATE_TRAVEL_CONTENT,
} from '../gql/mutations/tripCardContent'
import {
  GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID,
  GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
} from '../gql/querys/tripCardContent'
import { getProfilePhotoUrl } from '../utils/profile'
import {
  formatTripCardContentByDays,
  getTripCardContentUrl,
} from '../utils/travelContent'
import { IComment, IFileWithLocation, ILike, ITripCardContent } from '../types'
import { getMentions, getTags } from '../utils'
import IdsEnum from '../constants/ids.constants'
import { getImageUrl, uploadFile } from '../utils/useS3'
import { GET_MY_LIKES } from '../gql/querys/likes'
import uuidFormater from '../utils/formatUuid'
import { GET_COMMENTS_BY_PATH } from '../gql/querys/comments'
import EntityTypeEnum from '../constants/entityType.constants'
import removeDashes from '../utils/removeDashes'

export default {
  getLikedTripCardContents: async (tripCardContentIds: string[]) => {
    const result = await client.query({
      query: GET_PROFILE_LIKED_TRIP_CARD_CONTENTS,
      variables: {
        tripCardContentIds,
      },
    })
    let travelContents = result?.data?.travel_content
    travelContents = Promise.all(
      travelContents.map(async (te) => {
        const travelContentUrl = await getTripCardContentUrl(te.id, te.path)
        const profileName = te.travelog?.travatar?.user?.name
        const profileId = te.travelog?.travatar?.user?.id
        const profileCover = te.travelog?.travatar?.user?.profile_photo
        const profileCoverUrl = await getProfilePhotoUrl(
          profileCover,
          profileId
        )

        return {
          ...te,
          travelContentUrl,
          profileInfo: {
            name: profileName,
            photoUrl: profileCoverUrl,
          },
        }
      })
    )

    return travelContents
  },

  removeTripCardContents: async (travelContentsIds: string) => {
    try {
      await client.mutate({
        mutation: REMOVE_TRAVEL_CONTENTS,
        variables: { travelContentsIds },
      })
    } catch {
      throw new Error('Trip Content could not be deleted')
    }
  },
  insertToTripCard: async (
    filesWithLocation: IFileWithLocation[],
    tripCardId: string
  ): Promise<ITripCardContent[]> => {
    const travelContentInsertInput = filesWithLocation.map(
      (fileWithLocalization) => {
        const file = fileWithLocalization.file
        const localization = fileWithLocalization.localization
        return {
          trip_card_id: tripCardId,
          location: localization
            ? `(${localization?.latitude}, ${localization?.longitude})`
            : null,
          path: file.name,
          entity_type_id: file.type.includes('image')
            ? IdsEnum.ENTITY_TYPE_IMAGE
            : IdsEnum.ENTITY_TYPE_VIDEO,
        }
      }
    )
    const result = await client.mutate({
      mutation: INSERT_TRAVEL_CONTENTS,
      variables: { tripCardContents: travelContentInsertInput },
    })

    const tripCardContents = result.data.insert_trip_card_content.returning

    const tripCardContentsWithUrl = await Promise.all(
      tripCardContents.map(async (tc, index) => {
        const file = filesWithLocation[index].file
        const key = await uploadFile({
          rawKey: `${tc.id}/${file.name}`,
          contentType: file.type,
          file: file,
        })
        const url = await getImageUrl(key)

        return {
          ...tc,
          tripCardContentUrl: url,
        }
      })
    )

    return tripCardContentsWithUrl
  },
  editTripCardContent: async (
    travelContent: ITripCardContent
  ): Promise<ITripCardContent> => {
    travelContent.metadata = {
      ...travelContent.metadata,
      tags: getTags(travelContent.metadata.description),
      mentions: getMentions(travelContent.metadata.description),
    }
    const result = await client.mutate({
      mutation: UPDATE_TRAVEL_CONTENT,
      variables: {
        id: travelContent.id,
        metadata: travelContent.metadata,
        location: travelContent.location,
        isPrivate: travelContent.isPrivate,
      },
    })
    const travelContentUpdated = result.data.update_travel_content_by_pk
    return travelContentUpdated
  },

  completeTripCardContents: async (
    tripCardContents: ITripCardContent[],
    userId: string
  ) => {
    const contentPaths = tripCardContents.map(
      (tcc) => `${EntityTypeEnum.TRIP_CARD_CONTENT}.${removeDashes(tcc.id)}`
    )
    let resultMyLikes
    if (userId) {
      resultMyLikes = await client.query({
        query: GET_MY_LIKES,
        variables: { paths: contentPaths, userId },
      })
    }

    const myLikes: string[] = resultMyLikes?.data?.like?.map((like: ILike) => {
      const id = like?.path?.split('.')[1]
      return uuidFormater(id)
    })

    const contentCommentsResult = await client.query({
      query: GET_COMMENTS_BY_PATH,
      variables: { paths: contentPaths },
    })
    const contentComments: IComment[] = contentCommentsResult.data.comment

    return await Promise.all(
      tripCardContents.map(async (tcc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tcc.id, tcc.path)

        const comments = contentComments.reduce(
          (acc: IComment[], comment: IComment) => {
            if (uuidFormater(comment.path.split('.')[1]) === tcc.id) {
              return (acc = [...acc, comment])
            }
            return acc
          },
          []
        )
        const iLiked = myLikes?.find((id) => id === tcc?.id) ? true : false
        return {
          ...tcc,
          comments,
          iLiked,
          tripCardContentUrl,
        }
      })
    )
  },
  getPublicAndPrivates: async (tripPlanId) => {
    const result = await client.query({
      query: GET_ALL_TRIP_CARD_CONTENT_BY_TRIP_PLAN_ID,
      variables: { tripPlanId },
    })
    const tripCardContent = await Promise.all(
      result.data.travel_content.map(async (tc) => {
        const tripCardContentUrl = await getTripCardContentUrl(tc.id, tc.path)
        return {
          ...tc,
          tripCardContentUrl,
        }
      })
    )

    const formatedTripCardContent = formatTripCardContentByDays(tripCardContent)
    return formatedTripCardContent
  },
}

import { StoreSlice } from '.'
import EntityTypeEnum from '../constants/entityType.constants'
import IdsEnum from '../constants/ids.constants'
import InvalidUuidError from '../customErrors/InvalidUuid.error'
import commentService from '../services/commentService'
import followService from '../services/followService'
import likeService from '../services/likeService'
import travelElementContentService from '../services/travelElementContentService'
import travelElementService from '../services/travelElementService'
import {
  IComment,
  IFileWithLocation,
  ITravelElement,
  ITravelElementContent,
  ITravelElementRelatedTravelogs,
} from '../types'

type TravelElementSliceState = {
  travelElement: ITravelElement | null
  travelElements: ITravelElement[] | []
  globePoints: ITravelElement[] | []
  travelElementRelatedTravelogs: ITravelElementRelatedTravelogs[] | []
}

type TravelElementSliceActions = {
  getTravelElement(travelElementId: string, userId: string): void
  newTravelElement(newTravelElementData: ITravelElement)
  editTravelElement(editedTravelElementData: ITravelElement)
  addTravelElementLike(travelElementId, profileId): Promise<void>
  deleteTravelElementLike(travelElementId, profileId): Promise<void>
  getGlobePoints(): Promise<void>
  insertTravelElementFollow(travelElementId, profileId): Promise<void>
  deleteTravelElementFollow(travelElementId, profileId): Promise<void>
  getTravelElementRelatedTravelogs(travelElementId): Promise<void>
  addTravelElementContentLike(contentId, profileId): void
  deleteTravelElementContentLike(contentId, profileId): void
  addTravelElementComment(
    comment: string,
    travelElementId: string,
    userId: string
  ): Promise<void>
  deleteTravelElementComment(commentId: string, travelElementId: string): void
  addTravelElementContentComment(
    comment: string,
    contentId: string,
    userId: string
  ): Promise<void>
  deleteTravelElementContentComment(
    commentId: string,
    travelElementId: string,
    travelElementContentId: string
  ): void
}
export type TravelElementSlice = TravelElementSliceState &
  TravelElementSliceActions

const defaultTravelElementSliceState: TravelElementSliceState = {
  travelElement: null,
  travelElements: [],
  globePoints: [],
  travelElementRelatedTravelogs: [],
}

export const createTravelElementSlice: StoreSlice<TravelElementSlice> = (
  set,
  get
) => ({
  ...defaultTravelElementSliceState,

  getTravelElement: async (travelElementId) => {
    try {
      get().setStartLoading()

      const travelElements: ITravelElement[] = get().travelElements

      let travelElement = travelElements.find(
        (te: ITravelElement) => te.id === travelElementId
      )

      if (!travelElement) {
        travelElement = await travelElementService.getTravelElement(
          travelElementId
        )
        travelElements.push(travelElement)
      }

      get().setEndLoading()

      set({
        travelElement,
        travelElements,
      })
    } catch (error) {
      get().setEndLoading()
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  newTravelElement: async (newTravelElementData) => {
    try {
      get().setStartLoading({ travelElement: { main: false, mutation: true } })
      const currentProfileTravelElements = get().profileTravelElements

      await get().getGlobePoints()
      const globePoints = get().globePoints

      const newTravelElement = await travelElementService.newTravelElement(
        newTravelElementData,
        globePoints
      )

      const travelElementContents =
        await travelElementService.addTravelElementContentWithUrl(
          newTravelElement.id,
          newTravelElementData.travelElementContents
        )
      newTravelElement.travelElementContents = travelElementContents

      const profileTravelElements = [
        newTravelElement,
        ...currentProfileTravelElements,
      ]

      get().setAlertMessage('Travel element created successfully', 'success')
      get().setEndLoading()

      set({
        profileTravelElements,
        travelElement: newTravelElement,
      })

      get().setMutationSuccess(true)
    } catch (error) {
      get().setEndLoading()
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error.message)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  insertTravelElementFollow: async (
    travelElementId: string,
    profileId: string
  ) => {
    try {
      const travelElement = get().travelElement
      const oldtravelElements = get().travelElements

      await followService.addFollow(
        travelElementId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT,
        IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT
      )

      travelElement.followers.push(profileId)

      const travelElements = oldtravelElements.filter(
        (te: ITravelElement) => te.id !== travelElement.id
      )

      travelElements.push(travelElement)

      set({
        travelElement,
        travelElements,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  deleteTravelElementFollow: async (
    travelElementId: string,
    profileId: string
  ) => {
    try {
      const travelElementRetrieve = get().travelElement
      let travelElementsRetrieve: ITravelElement[] = get().travelElements

      await followService.deleteFollow(
        travelElementId,
        profileId,
        EntityTypeEnum.TRAVEL_ELEMENT
      )

      travelElementsRetrieve = travelElementsRetrieve.filter(
        (te: ITravelElement) => te.id !== travelElementId
      )

      travelElementRetrieve.followers = travelElementRetrieve.followers.filter(
        (follower) => follower != profileId
      )
      travelElementsRetrieve.push(travelElementRetrieve)

      set({
        travelElement: travelElementRetrieve,
        travelElements: travelElementsRetrieve,
      })
    } catch (error) {
      get().setAlertMessage(error.message, 'error')
      console.log(error)
    }
  },
  editTravelElement: async (editedTravelElementData) => {
    try {
      get().setStartLoading({ travelElement: { main: false, mutation: true } })

      const travelElementEdited = await travelElementService.editTravelElement(
        editedTravelElementData
      )

      const profileTravelElementsFiltered = get().profileTravelElements.filter(
        (te: ITravelElement) => te.id !== editedTravelElementData.id
      )

      const profileTravelElements = [
        travelElementEdited,
        ...profileTravelElementsFiltered,
      ]

      get().setEndLoading()
      get().setAlertMessage('Travel Element edited successfully', 'success')

      set({
        profileTravelElements,
        travelElement: travelElementEdited,
      })
    } catch (error) {
      get().setEndLoading()
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        console.log(error.message)
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  getGlobePoints: async () => {
    try {
      get().setStartLoading()
      const globePoints = await travelElementService.getGlobePoints()
      get().setEndLoading()

      set({ globePoints })
    } catch (error) {
      get().setEndLoading()
      if (error instanceof InvalidUuidError) {
        get().setNotFound(true)
      } else {
        get().setAlertMessage(error.message, 'error')
      }
    }
  },
  addTravelElementLike: async (travelElementId, profileId) => {
    const travelElement = get().travelElement
    travelElement.likes = [...travelElement.likes, profileId]
    likeService.addLike(
      travelElementId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT,
      IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT
    )

    set({
      travelElement,
    })
  },
  deleteTravelElementLike: async (travelElementId, profileId) => {
    const travelElement = get().travelElement
    travelElement.likes = travelElement.likes.filter(
      (like) => like !== profileId
    )
    likeService.deleteLike(
      travelElementId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT
    )

    set({
      travelElement,
    })
  },

  removeTravelElementContents: async (travelElementContentIds: string[]) => {
    try {
      const deletedTravelElementContents =
        await travelElementContentService.removeTravelElementContents(
          travelElementContentIds
        )

      const currentTravelElement = get().travelElement
      const currentTravelElementContents =
        currentTravelElement.travelElementContents
      const newTravelElementContents = currentTravelElementContents.filter(
        (cte: ITravelElementContent) =>
          !deletedTravelElementContents.find(cte.id)
      )

      currentTravelElement.travelElementContents = newTravelElementContents

      get().setAlertMessage(
        'The travel elements were deleted succesfully',
        'success'
      )
      set({
        travelElement: currentTravelElement,
      })
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  getTravelElementRelatedTravelogs: async (travelElementId) => {
    try {
      const travelElementRelatedTravelogs = get().travelElementRelatedTravelogs

      const newTravelogsReletad =
        await travelElementService.getTravelElementRelatedTravelogs(
          travelElementId
        )
      set({
        travelElementRelatedTravelogs: [
          ...travelElementRelatedTravelogs,
          newTravelogsReletad,
        ],
      })
    } catch (error) {
      console.log(error, '123')
      get().setAlertMessage(error.message, 'error')
    }
  },
  insertTravelElementContent: async (
    files: IFileWithLocation[],
    travelElementId: string
  ) => {
    try {
      const newTravelElementContents =
        await travelElementService.insertContents(files, travelElementId)

      const currentTravelElement = get().travelElement

      const currentTravelElementContent =
        currentTravelElement.travelElementContents

      currentTravelElement.travelElementContents = [
        ...currentTravelElementContent,
        ...newTravelElementContents,
      ]

      set({ travelElement: currentTravelElement })
      get().setAlertMessage('Images uploaded successfully', 'success')
    } catch (err) {
      console.log(err)
      get().setAlertMessage(err.message, 'error')
    }
  },
  addTravelElementContentLike: async (contentId, profileId) => {
    const travelElement = get().travelElement
    if (travelElement?.id) {
      const travelElementContents = travelElement?.travelElementContents?.map(
        (tec) => {
          if (tec.id === contentId) {
            return {
              ...tec,
              likesAmount: ++tec.likesAmount,
              iLiked: true,
            }
          }
          return tec
        }
      )
      travelElement.travelElementContents = travelElementContents
    }

    const itineraryTravelElements = get().itineraryTravelElements.map(
      (te: ITravelElement) => {
        const travelElementContents = te.travelElementContents.map(
          (tec: ITravelElementContent) => {
            if (tec.id === contentId) {
              return {
                ...tec,
                likesAmount: ++tec.likesAmount,
                iLiked: true,
              }
            }
            return tec
          }
        )
        return {
          ...te,
          travelElementContents,
        }
      }
    )

    const profileTravelElements = get().profileTravelElements.map(
      (te: ITravelElement) => {
        const travelElementContents = te.travelElementContents.map((tec) => {
          if (tec.id === contentId) {
            return {
              ...tec,
              likesAmount: ++tec.likesAmount,
              iLiked: true,
            }
          }
          return tec
        })
        return {
          ...te,
          travelElementContents,
        }
      }
    )

    await likeService.addLike(
      contentId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT_CONTENT,
      IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT_CONTENT
    )
    set({
      profileTravelElements,
      itineraryTravelElements,
      travelElement,
    })
  },
  deleteTravelElementContentLike: (contentId, profileId) => {
    const travelElement = get().travelElement
    if (travelElement?.id) {
      const travelElementContents = travelElement.travelElementContents.map(
        (tec) => {
          if (tec.id === contentId) {
            return {
              ...tec,
              likesAmount: --tec.likesAmount,
              iLiked: false,
            }
          }
          return tec
        }
      )
      travelElement.travelElementContents = travelElementContents
    }

    const profileTravelElements = get().profileTravelElements.map(
      (te: ITravelElement) => {
        const travelElementContents = te.travelElementContents.map((tec) => {
          if (tec.id === contentId) {
            return {
              ...tec,
              likesAmount: --tec.likesAmount,
              iLiked: true,
            }
          }
          return tec
        })
        return {
          ...te,
          travelElementContents,
        }
      }
    )

    const itineraryTravelElements = get().itineraryTravelElements.map((te) => {
      const travelElementContents = te.travelElementContents.map((tec) => {
        if (tec.id === contentId) {
          return {
            ...tec,
            likesAmount: --tec.likesAmount,
            iLiked: false,
          }
        }
        return tec
      })
      return { ...te, travelElementContents }
    })

    likeService.deleteLike(
      contentId,
      profileId,
      EntityTypeEnum.TRAVEL_ELEMENT_CONTENT
    )

    set({
      profileTravelElements,
      travelElement,
      itineraryTravelElements,
    })
  },
  addTravelElementComment: async (
    comment: string,
    travelElementId: string,
    userId: string
  ) => {
    const newComment = await commentService.addComment(
      travelElementId,
      userId,
      EntityTypeEnum.TRAVEL_ELEMENT,
      IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT,
      comment
    )

    const travelElement = get().travelElement
    if (travelElement) {
      const comments = [...travelElement.comments, newComment]
      travelElement.comments = comments
    }

    const travelElements = get().travelElements.map((te: ITravelElement) => {
      if (te.id === travelElementId) {
        const comments = [...te.comments, newComment]
        return {
          ...te,
          comments,
        }
      }
      return te
    })

    const profileTravelElements = get().profileTravelElements.map(
      (te: ITravelElement) => {
        if (te.id === travelElementId) {
          const comments = [...te.comments, newComment]
          return {
            ...te,
            comments,
          }
        }
        return te
      }
    )

    set({
      profileTravelElements,
      travelElements,
      travelElement,
    })
  },
  deleteTravelElementComment: async (
    commentId: string,
    travelElementId: string
  ) => {
    await commentService.deleteComment(commentId)

    const travelElement = get().travelElement
    if (travelElement) {
      const comments = travelElement.comments.filter(
        (comment: IComment) => comment.id !== commentId
      )
      travelElement.comments = comments
    }

    const travelElements = get().travelElements.map((te: ITravelElement) => {
      if (te.id === travelElementId) {
        const comments = te.comments.filter(
          (comment: IComment) => comment.id !== commentId
        )
        return {
          ...te,
          comments,
        }
      }
      return te
    })

    const profileTravelElements = get().profileTravelElements.map(
      (te: ITravelElement) => {
        if (te.id === travelElementId) {
          const comments = te.comments.filter(
            (comment: IComment) => comment.id !== commentId
          )
          return {
            ...te,
            comments,
          }
        }
        return te
      }
    )

    set({ travelElement, travelElements, profileTravelElements })
  },
  addTravelElementContentComment: async (
    comment: string,
    contentId: string,
    userId: string
  ) => {
    const newComment = await commentService.addComment(
      contentId,
      userId,
      EntityTypeEnum.TRAVEL_ELEMENT_CONTENT,
      IdsEnum.ENTITY_TYPE_TRAVEL_ELEMENT_CONTENT,
      comment
    )

    const travelElement = get().travelElement
    if (travelElement) {
      const travelElementContents = travelElement.travelElementContents.map(
        (tec) => {
          if (tec.id === contentId) {
            const comments = [...tec.comments, newComment]
            return {
              ...tec,
              comments,
            }
          }
          return tec
        }
      )
      travelElement.travelElementContents = travelElementContents
    }

    const travelElements: ITravelElement[] = get().travelElements.map(
      (te: ITravelElement) => {
        const travelElementContents = te.travelElementContents.map(
          (tec: ITravelElementContent) => {
            if (tec.id === contentId) {
              const comments = [...tec.comments, newComment]
              return {
                ...tec,
                comments,
              }
            }
            return tec
          }
        )
        return { ...te, travelElementContents }
      }
    )

    const profileTravelElements: ITravelElement[] =
      get().profileTravelElements.map((te: ITravelElement) => {
        const travelElementContents = te.travelElementContents.map((tec) => {
          if (tec.id === contentId) {
            const comments = [...tec.comments, newComment]
            return {
              ...tec,
              comments,
            }
          }
          return tec
        })
        return { ...te, travelElementContents }
      })

    set({
      profileTravelElements,
      travelElements,
      travelElement,
    })
  },
  deleteTravelElementContentComment: async (
    commentId: string,
    travelElementId: string,
    travelElementContentId: string
  ) => {
    await commentService.deleteComment(commentId)

    const travelElement = get().travelElement
    if (travelElement) {
      const travelElementContents = travelElement.travelElementContents.map(
        (tec) => {
          const comments = tec.comments.filter(
            (comment) => comment.id !== commentId
          )
          return {
            ...tec,
            comments,
          }
        }
      )
      travelElement.travelElementContents = travelElementContents
    }

    const travelElements = get().travelElements.map((te: ITravelElement) => {
      if (te.id === travelElementId) {
        const travelElementContents = te.travelElementContents.map((tec) => {
          if (tec.id === travelElementContentId) {
            const comments = tec.comments.filter(
              (comment) => comment.id !== commentId
            )
            return {
              ...tec,
              comments,
            }
          }
          return tec
        })
        return {
          ...te,
          travelElementContents,
        }
      }
      return te
    })

    const profileTravelElements = get().profileTravelElements.map(
      (te: ITravelElement) => {
        if (te.id === travelElementId) {
          const travelElementContents = te.travelElementContents.map((tec) => {
            if (tec.id === travelElementContentId) {
              const comments = tec.comments.filter(
                (comment) => comment.id !== commentId
              )
              return {
                ...tec,
                comments,
              }
            }
            return tec
          })
          return {
            ...te,
            travelElementContents,
          }
        }
        return te
      }
    )

    set({ travelElement, travelElements, profileTravelElements })
  },
})

import { gql } from '@apollo/client'
import {
  TRIP_CARD_FRAGMENT,
  TRIP_CARD_INFO_FRAGMENT,
} from '../fragments/tripCard'

const INSERT_TRIP_CARD = gql`
  mutation insertTripCard(
    $tripPlanId: uuid!
    $metadata: jsonb!
    $travelElementId: uuid
  ) {
    insert_trip_card_one(
      object: {
        trip_plan_id: $tripPlanId
        metadata: $metadata
        travel_element_id: $travelElementId
      }
    ) {
      ...TripCard
    }
  }
  ${TRIP_CARD_FRAGMENT}
`

const REMOVE_TRIP_CARDS = gql`
  mutation RemoveTripCards($tripCardIds: [uuid!]) {
    delete_trip_card(where: { id: { _in: $tripCardIds } }) {
      ...TripCardInfo
    }
  }
  ${TRIP_CARD_INFO_FRAGMENT}
`

const UPDATE_TRIP_CARD = gql`
  mutation UpdateTripCard($id: uuid!, $metadata: jsonb!) {
    update_trip_card_by_pk(
      pk_columns: { id: $id }
      _set: { metadata: $metadata }
    ) {
      ...TripCardInfo
    }
  }
  ${TRIP_CARD_INFO_FRAGMENT}
`

export { INSERT_TRIP_CARD, REMOVE_TRIP_CARDS, UPDATE_TRIP_CARD }

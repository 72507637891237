import { gql } from '@apollo/client'
import { COMMENT_FRAGMENT } from '../fragments/comment'
import { LIKES_FRAGMENT } from '../fragments/like'
import { TRAVELOG_FRAGMENT } from '../fragments/travelog'

const GET_TRAVELOG_BY_ID = gql`
  query getTravelogById($id: uuid!, $path: ltree!) {
    travelog_by_pk(id: $id) {
      ...Travelog
    }
    travelogLikes: like(where: { path: { _eq: $path } }) {
      ...Likes
    }
    travelogComments: comment(where: { path: { _eq: $path } }) {
      ...Comments
    }
  }
  ${TRAVELOG_FRAGMENT}
  ${LIKES_FRAGMENT}
  ${COMMENT_FRAGMENT}
`

const GET_TRAVELOGS = gql`
  query GetTravelogs($travelogIds: [uuid!]) {
    travelog(where: { id: { _in: $travelogIds } }) {
      ...Travelog
    }
  }
  ${LIKES_FRAGMENT}
  ${TRAVELOG_FRAGMENT}
`

const GET_TRAVEL_ELEMENT_RELATED_TRAVELOGS = gql`
  query GetTravelElementRelatedTravelogs($travelElementId: uuid!) {
    travelog(
      where: {
        trip_plan: {
          _and: {
            trip_cards: {
              _and: { travel_element_id: { _eq: $travelElementId } }
            }
          }
        }
      }
      limit: 5
    ) {
      ...Travelog
    }
  }
  ${TRAVELOG_FRAGMENT}
`

export {
  GET_TRAVELOG_BY_ID,
  GET_TRAVELOGS,
  GET_TRAVEL_ELEMENT_RELATED_TRAVELOGS,
}
